import { Injectable } from '@angular/core';
import { ClassLevel } from '../../app/shared/model/class-level.model';
import { environment } from '../../environments/environment';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import { Categorie } from '../../app/shared/model/categorie.model';
import {SERVER_API_URL, SERVICE_CATALOGUE} from '../../app/app.constants';

@Injectable({
  providedIn: 'root'
})
export class CourseCategoryService {
  public formationDomainList: Array<ClassLevel> = [];
  public oneClassLevelCategoriesList: Array<Categorie> = [];

  constructor(private http: HttpClient) { }

  setOneClassLevelCategoriesList(classLevel: ClassLevel) {
    this.oneClassLevelCategoriesList = [];

    this.http.get(environment.apiUrl + '/api/categories').subscribe(categoriesListObj => {
      const allCategoriesList = categoriesListObj as Array<ClassLevel>;
      const categoriesId: Array<string> = [];

      for (const oneCategoryFromClassLevel of classLevel.categories) {
        categoriesId.push(oneCategoryFromClassLevel.id);
      }

      for (const oneCategory of allCategoriesList) {
        if (categoriesId.includes(oneCategory.id)) {
          this.oneClassLevelCategoriesList.unshift(oneCategory);
        }
      }
      console.log(this.oneClassLevelCategoriesList);
    });
  }

  setClassLevelList() {
    this.http.get(SERVER_API_URL + SERVICE_CATALOGUE + '/api/class-levels' ).subscribe(classLevelsObj => {
      this.formationDomainList = classLevelsObj as Array<ClassLevel>;
    });
  }
}
