<mat-card class = "home-menu">
    <mat-grid-list cols="4" rowHeight="20:1">
      
      <mat-grid-tile [routerLink]="[ '/']">Adame-Techs</mat-grid-tile>
      
      <mat-grid-tile>
        
      </mat-grid-tile>
  
      <mat-grid-tile> </mat-grid-tile>
      <mat-grid-tile [ngSwitch]="isAuthenticated()">
        <button *ngSwitchCase="true" mat-stroked-button color="primary" (click)="logOut()" style="color: white" >Se Deconnecter</button>
        <button  *ngSwitchCase="false" mat-stroked-button color="primary" (click)="openConnexion()" style="color: white">Se connecter</button>
        <button *ngSwitchCase="false" mat-raised-button color="primary" (click)="openDialog()" >S'inscrire</button>
      </mat-grid-tile>
  
    </mat-grid-list>
  
  </mat-card>