import {
  trigger,
  transition,
  style,
  query,
  group,
  animateChild,
  animate,
  keyframes,
} from '@angular/animations';
import {Chasing} from '../interfaces/chasing';

// Basic
export const fader =
  trigger('routeAnimations', [
    // this determines how to apply styles from one animation to the next.
    transition('* <=> *', [ // the star arrow syntax defines a wild cars that applies to every single route transition
      /* Angular animations will apply two pseudo selectors to the elements that are animated: one called "enter" and one called "leave"
      * where "enter" is the new page and "leave" is the old one. And query allows us to actually select these elements from the DOM */
      query(':enter, :leave', [ // this is the old page hiding
        /* creating a starting point from which we can use animate in the new page and at the same time we immediately hide the old one.
        * Angular animations will apply theses steps one after the other */
        style({
          position: 'absolute',
          left: 0,
          width: '100%',
          opacity: 0,
          transform: 'scale(0) translateY(100%)',
        })
      ], { optional: true }),
      /* querying for the enter selector and use the animate method to setup the actual transition, we also define a style that's the
      * endpoint of the animation */
      query(':enter', [ // this is the new page animating in
        animate('600ms ease',
          style({ opacity: 1, transform: 'scale(1) translateY(0)' })
        ),
      ], { optional: true })
    ])
  ]);

// Positioned
export const slider =
  trigger('routeAnimations', [
    transition('* => isLeft', slideTo('left')),
    transition('* => isRight', slideTo('right')),
    transition('isRight => *', slideTo('left')),
    transition('isLeft => *', slideTo('right'))
  ]);

function slideTo(direction) {
  const optional = { optional: true};
  return [
    query(':enter, :leave', [
      style({
        position: 'absolute',
        top: 0,
        [direction]: 0,
        width: '100%'
      }),
    ]),
    query(':enter', [
      style({ [direction]: '-100%'})
    ]),
    group([ // we want the animation to happend in the same time, that's why we put them in a group
      query(':leave', [
        animate('600ms ease', style({ [direction]: '100%' }))
      ], optional),
      query(':enter', [
        animate('600ms ease', style({ [direction]: '0%'}))
      ])
    ])
  ];
}

// Css rotation
export const transformer =
  trigger('routeAnimations', [
    transition('* => isLeft', translateTo({ x: -100, y: -100, rotate: -720})),
    transition('* => isRight', translateTo({ x: 100, y: -100, rotate: 90})),
    transition('isRight => *', translateTo({ x: -100, y: -100, rotate: 360})),
    transition('isLeft => *', translateTo({ x: -100, y: -100, rotate: 360})),
  ]);

function translateTo({ x = 100, y = 0, rotate = 0}) {
  const optional = { optional: true};
  return [
    query(':enter, :leave', [
      style({
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%'
      })
    ], optional),
    query(':enter', [
      style({ transform: `translate(${x}%, ${y}%) rotate(${rotate}deg)`})
    ]),
    group([
      query(':leave', [
        animate('600ms ease-out',
          style({ transform: `translate(${x}%, ${y}%) rotate(${rotate}deg)`})
        )
      ])
    ])
  ];
}

// Keyframes

export const stepper =
  trigger('routeAnimations', [
    transition('toHome => toCourseList', chasing({
      enterDuration: 400, enterScale1: 0, enterTranslateX1: 0, enterOffset1: 0, enterOpacity1: 0.7,
      enterScale2: 1, enterTranslateX2: 0, enterOffset2: 1,
      leaveDuration: 300, leaveScale1: 1, leaveOffset1: 0,
      leaveOpacity2: 0, leaveScale2: 5, leaveTranslateX2: 0, leaveOffset2: 1
    } as Chasing)),
    transition('toCourseList => toCourse', chasing({
      enterDuration: 400, enterScale1: 0, enterTranslateX1: 0, enterOffset1: 0, enterOpacity1: 0.7,
      enterScale2: 1, enterTranslateX2: 0, enterOffset2: 1,
      leaveDuration: 300, leaveScale1: 1, leaveOffset1: 0,
      leaveOpacity2: 0, leaveScale2: 5, leaveTranslateX2: 0, leaveOffset2: 1
    } as Chasing)),
    transition('toCourse => toCourseList', chasing({
      enterDuration: 400, enterScale1: 5, enterTranslateX1: 0, enterOffset1: 0, enterOpacity1: 0.7,
      enterScale2: 1, enterTranslateX2: 0, enterOffset2: 1,
      leaveDuration: 300, leaveScale1: 1, leaveOffset1: 0,
      leaveOpacity2: 1, leaveScale2: 0, leaveTranslateX2: 0, leaveOffset2: 1
    } as Chasing)),
    transition('toVideo => toCourse', chasing({
      enterDuration: 400, enterScale1: 5, enterTranslateX1: 0, enterOffset1: 0, enterOpacity1: 0.7,
      enterScale2: 1, enterTranslateX2: 0, enterOffset2: 1,
      leaveDuration: 300, leaveScale1: 1, leaveOffset1: 0,
      leaveOpacity2: 1, leaveScale2: 0, leaveTranslateX2: 0, leaveOffset2: 1
    } as Chasing)),
    transition('toCourse => toVideo', chasing({
      enterDuration: 400, enterScale1: 0, enterTranslateX1: 0, enterOffset1: 0, enterOpacity1: 0.7,
      enterScale2: 1, enterTranslateX2: 0, enterOffset2: 1,
      leaveDuration: 300, leaveScale1: 1, leaveOffset1: 0,
      leaveOpacity2: 0, leaveScale2: 5, leaveTranslateX2: 0, leaveOffset2: 1
    } as Chasing)),
    transition('* => toHome', chasing({
      enterDuration: 400, enterScale1: 5, enterTranslateX1: 0, enterOffset1: 0, enterOpacity1: 0.7,
      enterScale2: 1, enterTranslateX2: 0, enterOffset2: 1,
      leaveDuration: 300, leaveScale1: 1, leaveOffset1: 0,
      leaveOpacity2: 1, leaveScale2: 0, leaveTranslateX2: 0, leaveOffset2: 1
    } as Chasing)),
  ]);

function chasing(params: Chasing) {
  const optional = { optional: true};
  return [
    query(':enter, :leave', [
      style({
        position: 'absolute',
        left: 0,
        width: '100%'
      })
    ], optional),
    group([
      query(':enter', [
        animate(`${params.enterDuration}ms ease`, keyframes([
          style({ transform: `scale(${params.enterScale1}) translateX(${params.enterTranslateX1}%)`, offset: params.enterOffset1,
          opacity: params.enterOpacity1}),
          style({ transform: `scale(${params.enterScale2}) translateX(${params.enterTranslateX2}%)`, offset: params.enterOffset2})
        ]))
      ], optional),
      query(':leave', [
        animate(`${params.leaveDuration}ms ease`, keyframes([
          style({ transform: `scale(${params.leaveScale1})`, offset: params.leaveOffset1}),
          style({ transform: `scale(${params.leaveScale2}) translateX(${params.leaveTranslateX2}%)`,
            offset: params.leaveOffset2, opacity: params.leaveOpacity2})
        ]))
      ], optional)
    ])
  ];
}
