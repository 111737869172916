import { Injectable } from '@angular/core';
import { Lesson } from '../../app/shared/model/lesson.model';

@Injectable({
  providedIn: 'root'
})
export class VideoService {

  constructor() { }

  getVideo(allLessons: Array<Lesson>, lessonId: string): Lesson {
    let lesson: Lesson;

    for (const oneVideo of allLessons) {

      if (oneVideo.id === lessonId) {
        lesson = oneVideo;
        break;
      }

    }

    return lesson;
  }
}
