import { Component, ElementRef, HostListener, ViewChild } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { HttpClient } from '@angular/common/http';
import { ActivatedRoute } from '@angular/router';
import { Course } from '../shared/model/course.model';
import { Video } from '../../interfaces/video';
import { CourseService } from '../../services/course/course.service';
import { VideoService } from '../../services/video/video.service';
import { Chapter } from '../shared/model/chapter.model';
import { ChapterService } from '../../services/chapter/chapter.service';
import {Lesson} from '../shared/model/lesson.model';

@Component({
  selector: 'app-video-player',
  templateUrl: './video-player.component.html',
  styleUrls: ['./video-player.component.scss']
})
export class VideoPlayerComponent {
  @ViewChild('videoPlayer') videoPlayer: ElementRef;

  private readonly courseId: string;
  private readonly chapterId: string;
  private readonly lessonId: string;

  private chapterInfos: Chapter;
  public lessonInfos: Lesson;

  public volumeValue: number;

  public timeDisplay: string;
  public juiceWidth: string;
  public isPaused = false;
  public isFullScreen = false;

  constructor(private iconRegistry: MatIconRegistry,
              private sanitizer: DomSanitizer,
              private route: ActivatedRoute,
              private http: HttpClient,
              private courseService: CourseService,
              private chapterService: ChapterService,
              private videoService: VideoService) {

    this.courseId = this.route.snapshot.paramMap.get('course-id');
    this.chapterId = this.route.snapshot.paramMap.get('chapter-id');
    this.lessonId = this.route.snapshot.paramMap.get('video-id');

    this.setVideoInfos();
    this.initIcons();

    setTimeout(() => {
      this.videoPlayer.nativeElement.addEventListener('timeupdate', () => {
        const video = this.videoPlayer.nativeElement;
        const juicePosition: number = video.currentTime / video.duration;
        this.juiceWidth = juicePosition * 100 + '%';

        this.timeDisplay = this.formatTime(video.currentTime) + '/' + this.formatTime(video.duration);

        if (video.ended) {
          this.isPaused = true;
        }
      });

      this.videoPlayer.nativeElement.addEventListener('dblclick', () => {
       this.toggleFullScreen();
      });

      this.playPause(); // custom autoplay
    }, 1000);
  }

  setVideoInfos() {
    this.chapterInfos = this.chapterService.getChapter(this.courseService.course.chapters, this.chapterId);
    this.lessonInfos = this.videoService.getVideo(this.chapterInfos.lessons, this.lessonId);
  }

  playPause() {
    const video = this.videoPlayer.nativeElement;

    if (video.paused) {
      this.isPaused = false;
      video.play();
    } else {
      this.isPaused = true;
      video.pause();
    }
  }

  initIcons() {
    this.iconRegistry.addSvgIcon('play', this.sanitizer.bypassSecurityTrustResourceUrl('assets/icons/play_arrow-white-18dp.svg'));
    this.iconRegistry.addSvgIcon('pause', this.sanitizer.bypassSecurityTrustResourceUrl('assets/icons/pause-white-18dp.svg'));
    this.iconRegistry.addSvgIcon('full-screen', this.sanitizer.bypassSecurityTrustResourceUrl('assets/icons/fullscreen-white-18dp.svg'));
    this.iconRegistry.addSvgIcon('full-screen-exit', this.sanitizer.bypassSecurityTrustResourceUrl('assets/icons/fullscreen_exit-white-18dp.svg'));
    this.iconRegistry.addSvgIcon('more-volume', this.sanitizer.bypassSecurityTrustResourceUrl('assets/icons/volume_up-white-18dp.svg'));
    this.iconRegistry.addSvgIcon('less-volume', this.sanitizer.bypassSecurityTrustResourceUrl('assets/icons/volume_down-white-18dp.svg'));
    this.iconRegistry.addSvgIcon('no-volume', this.sanitizer.bypassSecurityTrustResourceUrl('assets/icons/volume_off-white-18dp.svg'));
    this.iconRegistry.addSvgIcon('fast-forward', this.sanitizer.bypassSecurityTrustResourceUrl('assets/icons/fast_forward-white-18dp.svg'));
    this.iconRegistry.addSvgIcon('fast-rewind', this.sanitizer.bypassSecurityTrustResourceUrl('assets/icons/fast_rewind-white-18dp.svg'));
    this.iconRegistry.addSvgIcon('next', this.sanitizer.bypassSecurityTrustResourceUrl('assets/icons/skip_next-white-18dp.svg'));
    this.iconRegistry.addSvgIcon('previous', this.sanitizer.bypassSecurityTrustResourceUrl('assets/icons/skip_previous-white-18dp.svg'));
    this.iconRegistry.addSvgIcon('small-screen', this.sanitizer.bypassSecurityTrustResourceUrl('assets/icons/fullscreen_exit-white-18dp.svg'));
  }

  @HostListener('window:keydown', ['$event'])
  handleKeyboardEvent(event: KeyboardEvent) {
    const video = this.videoPlayer.nativeElement;

    switch (event.key) {
      case ' ':
      case 'k':
        this.playPause();
        break;
      case 'ArrowUp':
        video.volume += 0.1;
        break;
      case 'ArrowDown':
        video.volume -= 0.1;
        break;
      case 'ArrowLeft':
        video.currentTime -= 5;
        break;
      case 'ArrowRight':
        video.currentTime += 5;
        break;
    }
  }

  toggleFullScreen() {
    if (!document.fullscreenElement) {
      document.documentElement.requestFullscreen();

      this.isFullScreen = true;
    } else {
      if (document.exitFullscreen) {
        document.exitFullscreen();
      }

      this.isFullScreen = false;
    }
  }

  toggleMute() {
    if (this.videoPlayer.nativeElement.volume < 0.1) { // is mute
      this.videoPlayer.nativeElement.volume = this.volumeValue;
    } else {
      this.volumeValue = this.videoPlayer.nativeElement.volume; // cache for when we'll unmute
      this.videoPlayer.nativeElement.volume = 0.0;
    }

  }

  lessVolume() {
    this.videoPlayer.nativeElement.volume -= 0.1;
  }

  moreVolume() {
    this.videoPlayer.nativeElement.volume += 0.1;
  }

  formatTime(paramTime: number) {
    let hours = Math.floor(paramTime / 3600).toString();
    let minutes = Math.floor( paramTime / 60).toString();
    let seconds = Math.floor(paramTime - (parseInt(minutes, 10) * 60)).toString();

    if (hours.length === 1) {
      hours = '0' + hours;
    }
    if (minutes.length === 1) {
      minutes = '0' + minutes;
    }
    if (seconds.length === 1) {
      seconds = '0' + seconds;
    }

    const minutesAndSeconds = minutes + ':' + seconds;
    const hoursMinutesAndSeconds = hours + ':' + minutesAndSeconds;

    return (parseInt(hours, 10) > 0) ? hoursMinutesAndSeconds : minutesAndSeconds;
  }

  onload(urlPath: string) {
    const xhr = new XMLHttpRequest();

    xhr.open('GET', urlPath);
    xhr.responseType = 'arraybuffer';

    xhr.onload = (e) => {
      const blob = new Blob([xhr.response]);
      const url = URL.createObjectURL(blob);

      console.log(url);

      const img = document.getElementById('image');
      // img.src = url;
    };

    xhr.send();
  }

}
