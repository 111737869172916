<!-- <div style="margin-left: auto; margin-right: auto; text-align: center">
    <h1 mat-dialog-title>Bienvenus sur ADAME-TECHS</h1>
    <div mat-dialog-content style="">
        <p>Veuillez saisir les informations ci-dessous pour créer votre compte</p>
        <p>
            <mat-form-field [ngStyle]="{'width.px': 300}">
                <mat-label>Votre identifiant</mat-label>
                <input matInput type="text" [(ngModel)]="data.manageduservm.login">
            </mat-form-field>
        </p>

        <p>
            <mat-form-field [ngStyle]="{'width.px': 300}">
                <mat-label>Votre email</mat-label>
                <input matInput type="email" [(ngModel)]="data.manageduservm.email">
            </mat-form-field>
        </p>
        <p>
            <mat-form-field [ngStyle]="{'width.px': 300}">
                <mat-label>Veuillez entrer votre mot de passe</mat-label>
                <input matInput type="password" [(ngModel)]="data.manageduservm.password">
            </mat-form-field>
        </p>
        <p>
            <mat-form-field [ngStyle]="{'width.px': 300}">
                <mat-label>Veuillez confirmer votre mot de passe</mat-label>
                <input matInput type="password" [(ngModel)]="data.manageduservm.confirmationPassword">
            </mat-form-field>
        </p>
    </div>
    <div mat-dialog-actions> -->
        <!-- <button mat-button (click)="onNoClick()">Fermer</button> -->
        <!-- <button mat-button [mat-dialog-close]="data.manageduservm" cdkFocusInitial style="text-align: center">
            Creer mon compte</button>
    </div>

</div> -->
<jhi-register></jhi-register>