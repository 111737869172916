import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-gtcs',
  templateUrl: './gtcs.component.html',
  styleUrls: ['./gtcs.component.scss']
})
export class GtcsComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
