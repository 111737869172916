import { Component } from '@angular/core';
import {MatDialogRef} from '@angular/material/dialog';

@Component({
  selector: 'app-subscribe',
  templateUrl: './subscribe.component.html',
  styleUrls: ['./subscribe.component.scss']
})
export class SubscribeComponent {

  constructor(public dialogRef: MatDialogRef<SubscribeComponent>) { }

  onNoClick(): void {
    this.dialogRef.close();
  }
}
