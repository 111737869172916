import { Component, Input } from '@angular/core';
import { Course, ICourse } from '../shared/model/course.model';
import { ActivatedRoute, Router } from '@angular/router';
import { Chapter, IChapter } from '../shared/model/chapter.model';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { CourseService, PeriodicElement } from '../../services/course/course.service';
import { environment } from '../../environments/environment';
import { SubscribeComponent } from '../subscribe/subscribe.component';
import { MatDialog } from '@angular/material/dialog';
import { CourseDescriptionComponent } from '../course-description/course-description.component';
import { PublicInformationService } from '../shared/services/public-information.service';
import { PublicInformation, IPublicInformation } from '../shared/model/public-information.model';
import { HttpResponse } from '@angular/common/http';
import { TestBed } from '@angular/core/testing';
import { Lesson, ILesson } from '../shared/model/lesson.model';
import { SelectionModel } from '@angular/cdk/collections';
import { map } from 'rxjs/operators';
import {ProgressSpinnerMode} from '@angular/material/progress-spinner';
import {ThemePalette} from '@angular/material/core';
import { AccountService } from '../core/auth/account.service';

export interface DialogData {
  courseId: BigInteger;
}

@Component({
  selector: 'app-course',
  templateUrl: './course.component.html',
  styleUrls: ['./course.component.scss'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({height: '0px', minHeight: '0'})),
      state('expanded', style({height: '*'})),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],

})
export class CourseComponent  {

  account: any;

  // // @Input() color: ThemePalette;
  // @Input() diameter: number;
  // @Input() display: string | number;
  // // @Input() mode: ProgressSpinnerMode;
  // @Input() strokeWidth: number;
  // @Input() value: number;


  public selectedChapter: Chapter;
  public courseId: string;

  color: ThemePalette = 'primary';
  mode: ProgressSpinnerMode = 'determinate';

  listLessonsNames: any[] = [];
  listChapters: IChapter[] = [];
  selectedLessons: String[] = [];
  SelectedLessonsSaved: String[] = [];
  public course: any = [];
  avancementCours: number;
  chapitreSelectedLesson: String[];

  lessonAlreadyExist = false;


  constructor(private router: Router,
              private route: ActivatedRoute,
              public courseService: CourseService,
              public publicInformationService: PublicInformationService,
              public dialog: MatDialog,
              public accountService: AccountService) {
    this.courseId = this.route.snapshot.paramMap.get(environment.routes.course.params.courseId);

    this.courseService.setCourse(this.courseId).add(() => {
      this.courseService.setChaptersLessonsList();
    });

    this.getCourse().add(() => {
     // extract chapter and lessons
      // this.course.chapters.forEach(chapter => this.listChapters.push(chapter));
      for (const chapter of this.course.chapters) {
       for (const lesson of chapter.lessons) {
        this.listLessonsNames.push(lesson.name);
       }
      }

      // recupération des lesson sauvegardé
      this.SelectedLessonsSaved = ['2 - Qu\'est-ce que Angular?', '5 - Analyse approfondie de la CLI et dépannage', '20 - Utilisation des modèles de composants'];
      this.selectedLessons = this.SelectedLessonsSaved;
      // configuration de l'avancemnet
      this.calculAvancementCours();

      // TODO:penser a retirer ces console.log
      // console.log("test");
      // console.log(this.avancementCours);
      console.log(this.listLessonsNames);
    });



  }



  prepareSelectedLessons() {
    this.chapitreSelectedLesson = this.selectedLessons;
    console.log(this.chapitreSelectedLesson);
    console.log('test ON LOAD');

  //   document.addEventListener('readystatechange', (event)=>{
  //      // When window loaded ( external resources are loaded too- `css`,`src`, etc...)
  //   if (event.target.readyState === "complete") {
  //     alert("hi 2");
  // }

  //   })
  }

  saveSelectedLesson(nameLesson: any, nameChapter: any) {
      console.log(this.selectedLessons);
      console.log('nom chapitre : ' + nameChapter + ' nom lesson :' + nameLesson);
      console.log('taille du tabelau de lessons : ' + this.selectedLessons.length);

      const index = this.SelectedLessonsSaved.indexOf(nameLesson);
      if (index !== -1) {
        console.log('il existe bien dans la liste donc déja checké alors je le SUPPRIME !!!');
        this.SelectedLessonsSaved.splice(index, 1);
        this.selectedLessons = this.SelectedLessonsSaved;
      } else {
        console.log('il existe pas dans la liste alors je l\'ajoute !!!');
        this.SelectedLessonsSaved.push(nameLesson);
        this.selectedLessons = this.SelectedLessonsSaved;
      }


      // je récupère la liste de chapitre

      // je recherche le chapitre concerné
          // j'écrase la liste par la nouvelle liste.

      // sauvegarder la liste selectionner si
        // j'initalise la liste avec les data sauvé si la map est a zéro (changemnet de chapitre)
        // if(this.selectedLessons.length < this.SelectedLessonsSaved.length){
        //   this.selectedLessons=this.SelectedLessonsSaved;
        //   this.selectedLessons.push(nameLesson);
        // }else{
        //   this.SelectedLessonsSaved=this.selectedLessons;
        // }

      // calculer l'avancement pour l'affichage front
      this.calculAvancementCours();
      console.log('pourcentage d\'avancement' + this.avancementCours + ' %');

      // sauvegarder l'avancement

      //  console.log(this.selectedLessons);

  }

  isAuthenticated() {
    this.account = this.accountService.getIdentity();
    return this.accountService.isAuthenticated();
   }




  calculAvancementCours() {
    console.log(this.selectedLessons);
    console.log('selected lesson : ' + this.selectedLessons.length);
    console.log('lessons of course : ' + this.listLessonsNames.length);
    // this.listLessonsNames.forEach(lesson => console.log(lesson));
    this.avancementCours =  Math.round(this.selectedLessons.length / this.listLessonsNames.length * 100) ;

  }
  getCourse() {
    return this.courseService.find(this.courseId).subscribe(courseObj => {
      this.course = courseObj.body ;

    },
    err => console.log(err));
  }

  onClick(element) {
    this.courseService.expandedElement = this.courseService.expandedElement === element ? null : element;
    this.selectedChapter = element.chapter;
  }

  goToLesson(chapterId: string, lessonId: string) {
    // this.openSubscribeDialog();
     // TODO: uncoment for authenticated users
    const courseCategoryId = this.route.snapshot.paramMap.get(environment.routes.course.params.courseCategoryId);
    const courseId = this.route.snapshot.paramMap.get(environment.routes.course.params.courseId);

    this.router.navigate([environment.routes.video.name, courseCategoryId, courseId, chapterId, lessonId]);

  }

  openCourseDescriptionDialog(): void {
    // récupérer les publicInformation
    const idPublicINformation = this.courseService.course.publicInformations[0].id;

    // récupération des informations pour les apprentis
    const blockDeCompetence = this.courseService.course.blockDeCompetence;


    this.publicInformationService.find(idPublicINformation).subscribe((response: HttpResponse<IPublicInformation>) => {


     const dialogRef = this.dialog.open(CourseDescriptionComponent, {
        width: '90%',
        height: '100%',
        data: response.body
      });

      // console.log(response.body)
    });

  }

  // openCourseDescriptionDialogData(): void {
  //   const dialogRef = this.dialog.open(CourseDescriptionDialogDataComponent, {
  //     width: '90%',
  //     height: '100%',
  //     data: { courseId: this.courseId}
  //   });
  // }

  // openSubscribeDialog(): void {
  //   if (this.courseId !== '1169' && this.courseId !== '1151') { // TODO: delete in the future
  //     const dialogRef = this.dialog.open(SubscribeComponent, {
  //       width: '90%',
  //       height: '100%',
  //     });
  //   }
  // }


}
