import { Component, OnInit, Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { RouterOutlet, Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { LoginService } from '../core/login/login.service';
import { AuthServerProvider } from '../core/auth/auth-jwt.service';
import { AccountService } from '../core/auth/account.service';
import {SERVER_API_URL} from '../app.constants';


export interface RespToken {
  id_token: string;
}

export interface DialogData {
  manageduservm: any;
  // captcha: any;
  // loggin: boolean;
}

export interface DialogLogin {
  mail: string;
  password: string;
  auth: any;
}

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {


  title = 'school';

  name: string;
  prenom: string;
  mail: string;
  password: string;
  confirmationPassword: string;
  captcha: any;
  login = false;

  ManagedUserVM: any = {
    activated: true,
    authorities: [
      'ROLE_USER'
    ],
    name: '',
    prenom: '',
    email: '',
    password: '',
    confirmationPassword: '',
    captcha: '',
    login: 'false'
  };

  apiUrl = environment.apiUrl;
  auth: any = {
    username: '',
    password: '',
    rememberMe: false
  };

  constructor(public dialog: MatDialog, private http: HttpClient, private router: Router,  private loginService: LoginService, private accountService: AccountService) { }

  ngOnInit(): void {
  }

  openDialog(): void {
    const dialogRef = this.dialog.open(DialogCreateAccount, {
      width: '50%', height: '60%',
      data: {
        manageduservm: {
          activated: true,
          authorities: [
            'ROLE_USERAPP'
          ],
          name: '',
          prenom: '',
          email: '',
          password: '',
          confirmationPassword: '',
          langKey: 'fr',
          captcha: '',
          login: ''
        }
      }
    });

    dialogRef.afterClosed().subscribe(ManagedUserVM  => {
      console.log('The dialog was closed');
      console.log(ManagedUserVM );
      // ajout du signUp de RegisterService (ou Register)
      this.signUp(ManagedUserVM );
    });
  }

  signUp(ManagedUserVM: any): any {
    this.http.post(this.apiUrl + '/api/register', ManagedUserVM ).subscribe(response => {
      console.log(response);
    });
  }

  openConnexion(): void {
    console.log('token avant nouvel authentificaiton : ' + localStorage.getItem('authenticationToken'));
    const dialogRef = this.dialog.open(DialogConnexion, {
      width: '50%', height: '60%',
      data: {
        auth: {
          username: '',
          password: '',
          rememberMe: true
        }
      }
    });

    dialogRef.afterClosed().subscribe(credential => {

      this.loginService.login(credential).subscribe(account => {
        console.log(account);
        console.log(this.accountService.isAuthenticated());
      });

      console.log('token avant après authentificaiton : ' + localStorage.getItem('authenticationToken'));

    });

  }

  logOut() {
    this.loginService.logout();
    this.router.navigate(['/']).then(result => {window.location.href = 'http://localhost:4200/'; });
  }

  isAuthenticated() {

   return this.accountService.isAuthenticated();
  }




  // singIn() {
  //
  //   console.log(this.auth.password + ' username :' + this.auth.username + ' ' + this.apiUrl);
  //   console.log(this.auth);
  //   this.http.post(SERVER_API_URL + '/api/authenticate', this.auth).subscribe(response => {
  //     const resp = response as RespToken;
  //     console.log(resp);
  //     localStorage.setItem('authenticationToken', resp.id_token);
  //     console.log("my local storage : " + localStorage.getItem('authenticationToken'));
  //   }, err => console.log(err));
  //
  // }
}

@Component({
  selector: 'dialog-connexion',
  templateUrl: 'dialog-connexion.html'
})
export class DialogConnexion {

  constructor(
    public dialogRef: MatDialogRef<DialogConnexion>,
    @Inject(MAT_DIALOG_DATA) public data: DialogLogin) { }

  onNoClick(): void {
    this.dialogRef.close();
  }

}

@Component({
  selector: 'dialog-create-account',
  templateUrl: 'dialog-create-account.html',
})
export class DialogCreateAccount {

  constructor(
    public dialogRef: MatDialogRef<DialogCreateAccount>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData) { }

  onNoClick(): void {
    this.dialogRef.close();
  }

}

