import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Chapter } from '../../app/shared/model/chapter.model';

@Injectable({
  providedIn: 'root'
})
export class ChapterService {
  constructor(private http: HttpClient) { }

  getChapter(chaptersList: Array<Chapter>, chapterId: string): Chapter {
    let chapter: Chapter;

    for (const oneChapter of chaptersList) {

      if (oneChapter.id === chapterId) {
        chapter = oneChapter;
        break;
      }

    }

    return chapter;
  }
}
