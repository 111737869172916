import {Component, Inject} from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CourseService } from '../../services/course/course.service';
import { PublicInformation } from '../shared/model/public-information.model';

@Component({
  selector: 'app-course-description',
  templateUrl: './course-description.component.html',
  styleUrls: ['./course-description.component.scss']
})
export class CourseDescriptionComponent {
  public displayedColumns: Array<string> = ['type', 'duration', 'price'];
  id: any;
  publicInformations: PublicInformation;

  constructor(public dialogRef: MatDialogRef<CourseDescriptionComponent>,
              @Inject(MAT_DIALOG_DATA) private data: any,
              public courseService: CourseService) {
    this.publicInformations = this.data as PublicInformation;
    console.log(this.publicInformations);
  }

  onNoClick(): void {
    this.dialogRef.close();
  }
}
