<!-- <app-header></app-header> -->

<div [ngSwitch]="isAuthenticated()">
 
<h1 *ngSwitchCase="false">{{ env.appName }}<br /></h1>

<h1 *ngSwitchCase="true">Bonjour {{userIdentity.login}}</h1>
<p class="loading" *ngIf="isLoading">Chargement...</p>
<div class="content" *ngIf="!isLoading"  >
 
  <div>
    <mat-card class="example-card" *ngFor="let oneCategory of categoryService.formationDomainList"
              (click)="goToCourseList(oneCategory.id)">
      <mat-card-header>
        <mat-card-title>{{ oneCategory.title }}</mat-card-title>
        <mat-card-subtitle>{{ oneCategory.subtitle }}</mat-card-subtitle>
      </mat-card-header>
      <img mat-card-image src="{{ oneCategory.imgUrl }}" alt="image-of-the-class-level">
      <mat-card-actions>
        <button mat-raised-button>Apprendre !</button>
      </mat-card-actions>
    </mat-card>
  </div>
<div class="subscribe">
  <div class="main">
    <div class="container">
      <div><p> <mat-card-title style="text-align: center">Conseils d'experts</mat-card-title></p></div>
      <div><p><mat-card-title style="text-align: center">Apprenez a votre rythme</mat-card-title></p></div>
      <div><p><mat-card-title style="text-align: center">Cours ouverts 24/24</mat-card-title></p></div>
      <div><p><mat-card-title style="text-align: center">Attestation de réussite</mat-card-title></p></div>
    </div>
  </div>
</div>
  <div class="subscribe">

    <br/> <br/>
    <button mat-raised-button (click)="openDialog()">S'inscrire</button>
  </div>
</div>
</div>
