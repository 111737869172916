// These constants are injected via webpack environment variables.
// You can add more variables in webpack.common.js or in profile specific webpack.<dev|prod>.js files.
// If you change the values in the webpack config files, you need to re run webpack to update the application

// export const VERSION = process.env.VERSION;
// export const DEBUG_INFO_ENABLED = !!process.env.DEBUG_INFO_ENABLED;

//export const SERVER_API_URL = process.env.SERVER_API_URL;

export const SERVICE_CATALOGUE = '/services/catalogue';
export const SERVER_API_URL = 'http://ec2-13-38-18-224.eu-west-3.compute.amazonaws.com:8100';
// export const SERVER_API_URL = 'http://localhost:8080';
// export const BUILD_TIMESTAMP = process.env.BUILD_TIMESTAMP;

