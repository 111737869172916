import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from '../../environments/environment';
import { MatDialog } from '@angular/material/dialog';
import { SubscribeComponent } from '../subscribe/subscribe.component';
import { CourseCategoryService } from '../../services/category/course-category.service';
import { AccountService } from '../core/auth/account.service';
import { Account } from '../core/user/account.model';


@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent {
  public env = environment;
  public isLoading = false;
  public userIdentity: Account;


  constructor(private router: Router, public dialog: MatDialog, public categoryService: CourseCategoryService, public accountService: AccountService) {
    this.isLoading = true;
    setTimeout(() => {
      this.categoryService.setClassLevelList();
      this.isLoading = false;
    }, 500);
  }

  goToCourseList(classLevelId: string) {
    this.router.navigate([environment.routes.courseList.name, classLevelId]);
  }

  openDialog(): void {
    this.dialog.open(SubscribeComponent, {
      width: '90%',
      height: '100%'
    });
  }

  isAuthenticated(){
    this.userIdentity = this.accountService.getIdentity();
    return this.accountService.isAuthenticated();
   }
}
