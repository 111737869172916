<div >
    <div class="row justify-content-center">
        <div class="col-md-8">
            <h1 >Creation de Compte</h1>

            <div class="alert alert-success" *ngIf="success" >
                <strong>Inscription enregistrée !</strong> Veuillez vérifier votre e-mail pour confirmation.
            </div>

            <div class="alert alert-danger" *ngIf="error" >
                <strong>Échec de l'enregistrement!</strong> Veuillez réessayer plus tard.
            </div>

            <div class="alert alert-danger" *ngIf="errorUserExists" >
                <strong>Identifiant déjà enregistré !</strong> Veuillez en choisir un autre.
            </div>

            <div class="alert alert-danger" *ngIf="errorEmailExists" >
                <strong>Cet email est déjà utilisé!</strong> Veuillez en choisir un autre.
            </div>

            <div class="alert alert-danger" *ngIf="doNotMatch" >
                    Le mot de passe et sa confirmation ne correspondent pas !
            </div>
        </div>
    </div>
    <div class="row justify-content-center" style="color:black">
        <div class="col-md-8">
            <form name="form" role="form" (ngSubmit)="register()" [formGroup]="registerForm" *ngIf="!success">
                <div class="form-group">
                    <label  class="form-control-label " for="login" >Nom d'utilisateur</label>
                    <input type="text" class="form-control" id="login" name="login" placeholder="Veuillez entrer votre login"
                           formControlName="login">
                    <div *ngIf="registerForm.get('login').invalid && (registerForm.get('login').dirty || registerForm.get('login').touched)">
                        <small class="form-text text-danger" *ngIf="registerForm.get('login').errors.required">
                            Votre nom d'utilisateur est requis.
                        </small>
                        <small class="form-text text-danger" *ngIf="registerForm.get('login').errors.minlength"
                              >
                            Votre nom d'utilisateur doit comporter au moins 1 caractère.
                        </small>
                        <small class="form-text text-danger" *ngIf="registerForm.get('login').errors.maxlength"
                                >
                            Votre nom d'utilisateur ne peut pas dépasser 50 caractères.
                        </small>
                        <small class="form-text text-danger" *ngIf="registerForm.get('login').errors.pattern"
                              >
                            Votre nom d'utilisateur ne peut contenir que des lettres et des chiffres.
                        </small>
                    </div>
                </div>
                <div class="form-group">
                    <label class="form-control-label" for="email" >Email</label>
                    <input type="email" class="form-control" id="email" name="email" placeholder="Veuillez entrer votre email"
                             formControlName="email">
                    <div *ngIf="registerForm.get('email').invalid && (registerForm.get('email').dirty || registerForm.get('email').touched)">
                        <small class="form-text text-danger" *ngIf="registerForm.get('email').errors.required"
                               >
                               Votre email est requis.
                        </small>
                        <small class="form-text text-danger" *ngIf="registerForm.get('email').errors.invalid"
                               >
                               Votre email est invalide.
                        </small>
                        <small class="form-text text-danger" *ngIf="registerForm.get('email').errors.minlength"
                               >
                               Votre e-mail doit comporter au moins 5 caractères.
                        </small>
                        <small class="form-text text-danger" *ngIf="registerForm.get('email').errors.maxlength"
                              >
                              Votre e-mail ne peut pas dépasser 100 caractères.
                        </small>
                    </div>
                </div>
                <div class="form-group">
                    <label class="form-control-label" for="password" >Mot de password</label>
                    <input type="password" class="form-control" id="password" name="password" placeholder="Veuillez entrer votre mot de passe"
                            formControlName="password">
                    <div *ngIf="registerForm.get('password').invalid && (registerForm.get('password').dirty || registerForm.get('password').touched)">
                        <small class="form-text text-danger" *ngIf="registerForm.get('password').errors.required"
                                >
                                Votre mot de passe est requis.
                        </small>
                        <small class="form-text text-danger" *ngIf="registerForm.get('password').errors.minlength"
                               >
                               Votre mot de passe doit comporter au moins 4 caractères.
                        </small>
                        <small class="form-text text-danger" *ngIf="registerForm.get('password').errors.maxlength"
                                >
                                Votre mot de passe ne peut pas dépasser 50 caractères.
                        </small>
                    </div>
                    <jhi-password-strength-bar [passwordToCheck]="registerForm.get('password').value"></jhi-password-strength-bar>
                </div>
                <div class="form-group">
                    <label class="form-control-label" for="confirmPassword" >Mot de passe de confirmation</label>
                    <input type="password" class="form-control" id="confirmPassword" name="confirmPassword" placeholder="Veuillez entrer confirmer votre mot de passe"
                            formControlName="confirmPassword">
                    <div *ngIf="registerForm.get('confirmPassword').invalid && (registerForm.get('confirmPassword').dirty || registerForm.get('confirmPassword').touched)">
                        <small class="form-text text-danger" *ngIf="registerForm.get('confirmPassword').errors.required"
                               >
                               Votre mot de passe de confirmation est requis.
                        </small>
                        <small class="form-text text-danger" *ngIf="registerForm.get('confirmPassword').errors.minlength"
                              >
                              Votre mot de passe de confirmation doit comporter au moins 4 caractères.
                        </small>
                        <small class="form-text text-danger" *ngIf="registerForm.get('confirmPassword').errors.maxlength"
                               >
                               Votre mot de passe de confirmation ne peut pas dépasser 50 caractères.
                        </small>
                    </div>
                </div>

                <button type="submit" [disabled]="registerForm.invalid" class="btn btn-primary" >Créer mon compte</button>
            </form>
            <p></p>
            <div class="alert alert-warning">
                <span >Si vous avez déja un compte vous pouvez  </span>
                <a class="alert-link" (click)="openLogin()" >Vous connecter</a><span >, afin d'accéder a votre compte</span>
            </div>
        </div>
    </div>
</div>
