<app-header></app-header>
<mat-sidenav-container class="all-wrap">
  <mat-sidenav #sidenav over></mat-sidenav>

  <mat-sidenav-content class="page-wrap ...">
    <main [@routeAnimations]="prepareRoute(outlet)" class="content">
      <router-outlet #outlet="outlet"></router-outlet>
    </main>

    <app-footer></app-footer>
  </mat-sidenav-content>
</mat-sidenav-container>
