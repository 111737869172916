<div>
    <h1>Les droits de l'apprenti</h1>
    <p>L’apprenti bénéficie des mêmes conditions de travail et de protection sociale que les autres salariés de
        l’entreprise</p>
    <ul>
        <li>Le droit au respect</li>
        <li>Le respect des horaires légaux du travail (35 heures par semaine sauf dispositions conventionnelles
            particulières) et des jours de repos
        </li>
    </ul>
    <p><strong>Les apprentis de moins de 18 ans</strong> ne peuvent pas faire d'heures supplémentaires, sauf
        autorisation préalable de l'Inspection du travail et après avis conforme du médecin du travail de l'entreprise.
    </p>
    <p>Le travail de nuit, de 22 heures à 6 heures, est régi par des dispositions identiques</p>
    <ul>
        <li>Une rémunération déterminée en pourcentage du SMIC ou du salaire minimum conventionnel s’il est plus
            favorable.
            Cette rémunération est exonérée d’impôt sur le revenu
        </li>
        <li>5 semaines de congés payés par an. Il a également droit à 5 jours ouvrables pour préparer l'examen dans le
            mois qui précède l'épreuve (article L6222- 35 du Code du Travail).
        </li>
        <li>Les indemnités journalières par la Sécurité Sociale en cas d’arrêt de travail (maladie…) ou d’accident du
            travail
        </li>
        <li>Des années d’apprentissage pour la retraite</li>
        <li>Des allocations familiales peuvent être perçues par les parents jusqu’aux 20 ans de l’apprenti si son
            salaire ne dépasse pas 55% du SMIC.
        </li>
    </ul>

    <h1>Les devoirs de l'apprenti</h1>
    <p>L’apprenti s’engage à :
    </p>
    <ul>
        <li>s’impliquer activement dans sa formation, à se conformer aux horaires et à suivre avec assiduité
            l’enseignement dispensé au CFA et en entreprise
        </li>
        <li>Comme tout salarié, l'apprenti doit justifier de ses absences (prévenir et justifier des retards éventuels).
        </li>
        <li>En cas de maladie, l'employeur doit être prévenu et recevoir un certificat médical dans les 48 heures.
            Attention : en cas d’absence injustifiée de l’apprenti supérieur à 10% des heures prévues par an, l’aide
            régionale versée à l’employeur n’est pas versée.
        </li>
        <li>Avoir un comportement responsable et professionnel en centre de formation et en entreprises, prendre soin
            des lieux, du matériel et des moyens mis à sa disposition.
        </li>
        <li>Respecter les règlements intérieurs de l’entreprise et du CFA
        </li>
        <li>Participer aux épreuves liées à la formation : examen, tests, évaluations, contrôles en cours de formation y
            compris en entreprise.
        </li>
        <li>Signaler au maître d’apprentissage (ou à l’employeur) et au CFA toute information, tout changement de
            situation personnelle ou difficulté qui pourraient avoir une incidence sur le déroulement de la formation.
        </li>
        <li>Solliciter, autant que de besoin, l’aide de l’employeur, de l’équipe du CFA et les services du conseiller
            tuteur en insertion référent au CFA.
        </li>
    </ul>
</div>
