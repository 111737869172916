<div>
    <div class="row justify-content-center" >
        <div>
            <h1>Activation</h1>

            <div class="alert alert-success" *ngIf="success">
                <span ><strong>Votre utilisateur a été activé.</strong> Merci de vous </span>
                <a class="alert-link" (click)="login()">connecter</a>.
            </div>

            <div class="alert alert-danger" *ngIf="error">
                <strong>Votre utilisateur n'est pas activé.</strong> Veuillez créer un compte pour vous authentifier.
            </div>
        </div>
    </div>
    <div class="subscribe row justify-content-center">
       <a [routerLink]="['/']"><button mat-raised-button router>retour a l'acceuil</button></a> 
      </div>
</div>