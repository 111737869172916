import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import * as moment from 'moment';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { DATE_FORMAT } from '../../shared/constants/input.constants';
import { map } from 'rxjs/operators';

import {SERVER_API_URL, SERVICE_CATALOGUE} from '../../app.constants';
import { createRequestOption } from '../../shared/util/request-util';
import { IPublicInformation } from '../../shared/model/public-information.model';

type EntityResponseType = HttpResponse<IPublicInformation>;
type EntityArrayResponseType = HttpResponse<IPublicInformation[]>;

@Injectable({ providedIn: 'root' })
export class PublicInformationService {
  public resourceUrl = SERVER_API_URL + SERVICE_CATALOGUE + '/api/public-informations';

  constructor(protected http: HttpClient) {}

  create(publicInformation: IPublicInformation): Observable<EntityResponseType> {
    const copy = this.convertDateFromClient(publicInformation);
    return this.http
      .post<IPublicInformation>(this.resourceUrl, copy, { observe: 'response' })
      .pipe(map((res: EntityResponseType) => this.convertDateFromServer(res)));
  }

  update(publicInformation: IPublicInformation): Observable<EntityResponseType> {
    const copy = this.convertDateFromClient(publicInformation);
    return this.http
      .put<IPublicInformation>(this.resourceUrl, copy, { observe: 'response' })
      .pipe(map((res: EntityResponseType) => this.convertDateFromServer(res)));
  }

  find(id: string): Observable<EntityResponseType> {
    return this.http
      .get<IPublicInformation>(`${this.resourceUrl}/${id}`, { observe: 'response' })
      .pipe(map((res: EntityResponseType) => this.convertDateFromServer(res)));
  }

  query(req?: any): Observable<EntityArrayResponseType> {
    const options = createRequestOption(req);
    return this.http
      .get<IPublicInformation[]>(this.resourceUrl, { params: options, observe: 'response' })
      .pipe(map((res: EntityArrayResponseType) => this.convertDateArrayFromServer(res)));
  }

  delete(id: string): Observable<HttpResponse<any>> {
    return this.http.delete<any>(`${this.resourceUrl}/${id}`, { observe: 'response' });
  }

  protected convertDateFromClient(publicInformation: IPublicInformation): IPublicInformation {
    const copy: IPublicInformation = Object.assign({}, publicInformation, {
      dateCreation:
        publicInformation.dateCreation != null && publicInformation.dateCreation.isValid() ? publicInformation.dateCreation.toJSON() : null,
      dateModification:
        publicInformation.dateModification != null && publicInformation.dateModification.isValid()
          ? publicInformation.dateModification.toJSON()
          : null
    });
    return copy;
  }

  protected convertDateFromServer(res: EntityResponseType): EntityResponseType {
    if (res.body) {
      res.body.dateCreation = res.body.dateCreation != null ? moment(res.body.dateCreation) : null;
      res.body.dateModification = res.body.dateModification != null ? moment(res.body.dateModification) : null;
    }
    return res;
  }

  protected convertDateArrayFromServer(res: EntityArrayResponseType): EntityArrayResponseType {
    if (res.body) {
      res.body.forEach((publicInformation: IPublicInformation) => {
        publicInformation.dateCreation = publicInformation.dateCreation != null ? moment(publicInformation.dateCreation) : null;
        publicInformation.dateModification = publicInformation.dateModification != null ? moment(publicInformation.dateModification) : null;
      });
    }
    return res;
  }
}
