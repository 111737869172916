<style>
.dialog-container {
    margin-left: auto; margin-right: auto; text-align: center;font-size: 200%; margin-top: 5%;
}
</style>

<div class="dialog-container">
       
        <div mat-dialog-content >
            <p>Veuillez saisir les informations ci-dessous pour créer votre compte</p>
            <p>
                <mat-form-field style="width: 50%">
                    <mat-label>Votre email</mat-label>
                    <input matInput type="email" [(ngModel)]="data.auth.username">
                </mat-form-field>
            </p>
            <p>
                <mat-form-field style="width: 50%">
                    <mat-label>Veuillez entrer votre mot de passe</mat-label>
                    <input matInput type="password" [(ngModel)]="data.auth.password">
                </mat-form-field>
            </p>
        </div>

        <div mat-dialog-actions>
            <button mat-button [mat-dialog-close]="data.auth" cdkFocusInitial style="margin: 0 auto; width: 40%;">Je me connecte !</button>
        </div>

</div>