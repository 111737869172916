<div class="content">
    <h1>Pourquoi nous rejoindre ?</h1>

    <ul>
        <li><h2><b>95%</b> de nos apprenants sont entrés en poste à l’issue de leur formation</h2></li>
        <li>
            <h2>une offre composée de formations à des métiers d’avenir dont les profils sont fortement demandés</h2>
        </li>
        <li>
            <h2>
                mettez un terme aux déplacements et aux affres des transports en commun, étudiez depuis le confort de
                votre domicile
            </h2>
        </li>
        <li>
            <h2>
                accédez au contenu des cours à tous moments et ce autant de fois que vous le désirez afin d’en
                maîtriser la substance
            </h2>
        </li>
    </ul>
</div>
