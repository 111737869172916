<div>
    <div class="row justify-content-center">
        <div class="col-md-8" *ngIf="account$ | async as account">
            <h2 >Password pour [<strong>{{account.login}}</strong>]</h2>

            <div class="alert alert-success" *ngIf="success" >
                <strong>Password changed!</strong>
            </div>
            <div class="alert alert-danger" *ngIf="error"  >
                <strong>Une erreur est survenue!</strong> Le mot de passe n'a pas pu être modifié.
            </div>

            <div class="alert alert-danger" *ngIf="doNotMatch" >
                    Le mot de passe et sa confirmation ne correspondent pas !
            </div>

            <form name="form" role="form" (ngSubmit)="changePassword()" [formGroup]="passwordForm">

                <div class="form-group">
                    <label class="form-control-label" for="currentPassword" >Votre mot de passe actuel</label>
                    <input type="password" class="form-control" id="currentPassword" name="currentPassword"
                           placeholder="Entrez votre mot de passe'"
                           formControlName="currentPassword">
                    <div *ngIf="passwordForm.get('currentPassword').invalid && (passwordForm.get('currentPassword').dirty || passwordForm.get('currentPassword').touched)" >
                        <small class="form-text text-danger"
                               *ngIf="passwordForm.get('currentPassword').errors.required" >
                            Votre mot de passe est requis.
                        </small>
                    </div>
                </div>
                <div class="form-group">
                    <label class="form-control-label" for="newPassword" >Nouveau mot de passe</label>
                    <input type="password" class="form-control" id="newPassword" name="newPassword"
                           placeholder="Votre nouveau mot de passe"
                           formControlName="newPassword">
                    <div *ngIf="passwordForm.get('newPassword').invalid && (passwordForm.get('newPassword').dirty || passwordForm.get('newPassword').touched)">
                        <small class="form-text text-danger"
                           *ngIf="passwordForm.get('newPassword').errors.required" >
                           Votre mot de passe est requis.
                        </small>
                        <small class="form-text text-danger"
                           *ngIf="passwordForm.get('newPassword').errors.minlength" >
                           Votre mot de passe doit comporter au moins 4 caractères.
                        </small>
                        <small class="form-text text-danger"
                           *ngIf="passwordForm.get('newPassword').errors.maxlength" >
                           Votre mot de passe ne peut pas dépasser 50 caractères.
                        </small>
                    </div>
                    <jhi-password-strength-bar [passwordToCheck]="passwordForm.get('newPassword').value"></jhi-password-strength-bar>
                </div>
                <div class="form-group">
                    <label class="form-control-label" for="confirmPassword" >Confirmation du nouveau mot de passe</label>
                    <input type="password" class="form-control" id="confirmPassword" name="confirmPassword"
                           placeholder="veuillez confirmer votre mot de passe"
                           formControlName="confirmPassword">
                    <div *ngIf="passwordForm.get('confirmPassword').invalid && (passwordForm.get('confirmPassword').dirty || passwordForm.get('confirmPassword').touched)">
                        <small class="form-text text-danger"
                           *ngIf="passwordForm.get('confirmPassword').errors.required" jhiTranslate="global.messages.validate.confirmpassword.required">
                           Votre mot de passe de confirmation est requis.
                        </small>
                        <small class="form-text text-danger"
                           *ngIf="passwordForm.get('confirmPassword').errors.minlength" jhiTranslate="global.messages.validate.confirmpassword.minlength">
                           Votre mot de passe de confirmation doit comporter au moins 4 caractères.
                        </small>
                        <small class="form-text text-danger"
                           *ngIf="passwordForm.get('confirmPassword').errors.maxlength" jhiTranslate="global.messages.validate.confirmpassword.maxlength">
                           Votre mot de passe de confirmation ne peut pas dépasser 50 caractères.
                        </small>
                    </div>
                </div>
                <button type="submit" [disabled]="passwordForm.invalid" class="btn btn-primary" jhiTranslate="password.form.button">Enregistrer</button>
            </form>
        </div>
    </div>
</div>
