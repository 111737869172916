import { Component } from '@angular/core';
import { AdvantagesComponent } from '../advantages/advantages.component';
import { MatDialog } from '@angular/material/dialog';
import {DroitDevoirApprentisComponent} from '../droit-devoir-apprentis/droit-devoir-apprentis.component';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent {

  constructor(private dialog: MatDialog) { }
  
  openAdvantagesDialog(): void {
    this.dialog.open(AdvantagesComponent, {
      width: '90%',
      height: '100%'
    });
  }

  openDroitDevoirApprentiDialog(): void {
    this.dialog.open(DroitDevoirApprentisComponent, {
      width: '90%',
      height: '100%'
    });
  }

}
