<footer id="footer">
    <ul class="copyright icons">
        <li>
            <a href="#" target="_blank" class="icon alt fa-youtube">
                <span class="label">Youtube</span>
            </a>
        </li>
        <li>
            <a href="#" target="_blank" class="icon alt fa-facebook">
                <span class="label">Facebook</span>
            </a>
        </li>
        <li>
            <a href="#" target="_blank" class="icon alt fa-twitter">
                <span class="label">Twitter</span>
            </a>
        </li>
        <li>
            <a href="#" target="_blank" class="icon alt fa-linkedin">
                <span class="label">LinkedIn</span>
            </a>
        </li>
        <li>
            <a href="#" target="_blank" class="icon alt fa-instagram">
                <span class="label">Instagram</span>
            </a>
        </li>
        <!--
        <li><a href="#" class="icon alt fa-dribbble"><span class="label">Dribbble</span></a></li>
        <li><a href="#" class="icon alt fa-envelope"><span class="label">Email</span></a></li>
        -->
        <!-- <li><button mat-raised-button [routerLink]="['/gtcs']">CGV</button></li> -->

        <li><a (click)="openAdvantagesDialog()">Pourquoi nous rejoindre ?</a></li>
        <li><a (click)="openDroitDevoirApprentiDialog()">Les Droits et Devoir de l'apprentis</a></li>
        <li>
            <a [routerLink]="['/gtcs']" style="text-decoration: none">
                Conditions Générales de Vente
            </a>
        </li>
        <li >FRANCE, 2 bis rue pierre de Ronsard, Mantes-La-Jolie, 78200</li>
        <li>&copy; Adame-Techs 2022</li>
    </ul>

<!--    <ul class="copyright icons">-->
<!--        <li >FRANCE, 2 bis rue pierre de Ronsard, Mantes-La-Jolie, 78200</li>-->
<!--        <li>&copy; Adame-Techs 2022</li>-->
<!--    </ul>-->
</footer>
