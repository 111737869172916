<mat-card class="home-menu">
    <mat-grid-list cols="4" rowHeight="20:1">

        <mat-grid-tile [routerLink]="[ '/']">Learn City</mat-grid-tile>

        <mat-grid-tile>
            <mat-form-field [ngStyle]="{'width.px': 250}">
                <input matInput placeholder="Que souhaitez vous apprendre ?" aria-label="Search">
            </mat-form-field>
        </mat-grid-tile>

        <mat-grid-tile> Lions School Pour les entreprises</mat-grid-tile>
        <mat-grid-tile>
            <button [routerLink]="[ '/cour-a-apprendre']" mat-stroked-button color="primary">commande</button>
            <button [routerLink]="[ '/professeur']" mat-stroked-button color="primary">Professeur</button>
            <button mat-raised-button color="primary" [routerLink]="[ '/mes-cours']">Mes cours</button>
        </mat-grid-tile>

    </mat-grid-list>

</mat-card>