import { HttpInterceptor, HttpRequest, HttpHandler } from '@angular/common/http';


export class AuthInterceptorService implements HttpInterceptor{
    intercept(request: HttpRequest<any>, next: HttpHandler){
        console.log('request is on its way');
        // const modifiedRequest = req.clone({
        //     headers: req.headers.append('id_token', localStorage.getItem('id_token'))
        // })
        const token = localStorage.getItem('authenticationToken');
    if (token) {
      request = request.clone({
        setHeaders: {
          Authorization: 'Bearer ' + token
        }
      });
    }
        return next.handle(request);
    }
}