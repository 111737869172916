<div class="content" >
    <h1 style="color:white">{{ publicInformations.name }}</h1>
    <mat-divider></mat-divider>

    <div>
        <h2><b>Descriptif : </b></h2>
        <p>{{ publicInformations.descriptif }}</p>
    </div>
    <div>
        <h2><b>Objectifs Operationnel : </b></h2>
        <p>{{ publicInformations.objectifs }}</p>
    </div>
    <div>
        <h2><b>Programme de formation : </b></h2>
        <p *ngFor="let chapitre of courseService.course.chapters">
            
            {{chapitre.name}}
          <span *ngFor="let lesson of chapitre.lessons">
                <br />{{lesson.name}}
        </span>
        </p>
    </div>
    <div>
        <h2><b>Durée en Heures : </b></h2>
        <p>{{ publicInformations.dureeEnHeure }} Heures</p>
    </div>

    <div>
        <h2><b>Objectifs pédagogiques : </b></h2>
        <p>{{ publicInformations.commentaire }}</p>
    </div>
    <div>
        <h2><b>Déroulé de la formation : </b></h2>
        <p>Accès à du matériel didactique en ligne, (vidéos de cours, des fichiers PDF, des quiz et des exercices pratiques).
            Participation à des forums de discussion en ligne avec d'autres étudiants et un professeur pour poser des questions et échanger des idées.
            Travail individuel ou en groupe sur des projets et des devoirs.
            Possibilité de communiquer avec un professeur ou un mentor pour obtenir de l'aide ou des conseils personnalisés.
            Évaluation de la performance à l'aide de tests et d'examens en ligne.</p>
    </div>


    <div>
        <h2><b>Modalités : </b></h2>
        <p>{{ publicInformations.modalites }}</p>
    </div>

    <div>
        <h2><b>Délais d'accès : </b></h2>
        <p>{{ publicInformations.delaisDAcces }}</p>
    </div>

    <div>
        <h2><b>Prérequis : </b></h2>
        <p>{{ publicInformations.prerequis }}</p>
    </div>

    <div>
        <h2><b>Tarifs : </b></h2>
        <p>{{ publicInformations.tarifs }}€ HT</p>
    </div>

    <div>
        <h2><b>Contacts : </b></h2>
        <p>{{ publicInformations.contacts }}</p>
    </div>

    <div>
        <h2><b>Méthodes mobilisées : </b></h2>
        <p>{{ publicInformations.methodesMobilisees }}</p>
    </div>

    <div>
        <h2><b>Modalités d'évaluation : </b></h2>
        <p>{{ publicInformations.evaluationModalites }}</p>
    </div>

    <div>
        <h2><b>Accessibilité pour les personnes en situation de handicap : </b></h2>
        <p>{{ publicInformations.handicapeAccessibilites }}</p>
    </div>

    <div>
        <h2><b>Type de formation : </b></h2>
        <p>{{ publicInformations.typeFormation }}</p>
    </div>
    <div>
        <h2><b>Prériode de formation : </b></h2>
        <p *ngFor="let periodeFormation of publicInformations.periodeFormations">
      du   {{ periodeFormation.dateDebut }} au {{periodeFormation.dateFin}}</p> 
    </div>

    <div>
        <h2><b>Adresse postale : </b></h2>
        <p>{{ publicInformations.postalAdress }}</p>
    </div>

    <div>
        <h2><b>Lieu : </b></h2>
        <p>{{ publicInformations.lieu }}</p>
    </div>

    <div>
        <h2><b>Régions : </b></h2>
        <p *ngFor="let oneRegion of publicInformations.regions">
            {{ oneRegion.name }}
        </p>
    </div>

<!--    BLOCK Apprentis
blockDeCompetence,
          dureeEnHeure,
          tauxReussitExamen,
          validationDeBlockCompetencePossible,
          equivalences,
          passerelles,
          suitesDeParcours,
          debouches
-->
    <div>
        <h2><b>Taux de réussite : </b></h2>
        <p>{{ publicInformations.tauxReussitExamen }} %</p>
    </div>
    <div>
        <h2><b>validation de block de compétences possible : </b></h2>
        <p *ngIf="publicInformations.validationDeBlockCompetencePossible === true " >Oui</p>
        <p *ngIf="publicInformations.validationDeBlockCompetencePossible != true " >Oui</p>
    </div>
    <div>
        <h2><b>equivalences : </b></h2>
        <p>{{ publicInformations.equivalences }}</p>
    </div>
    <div>
        <h2><b>passerelles : </b></h2>
        <p>{{ publicInformations.passerelles }}</p>
    </div>
    <div>
        <h2><b>suitesDeParcours : </b></h2>
        <p>{{ publicInformations.suitesDeParcours }}</p>
    </div>
    <div>
        <h2><b>debouches : </b></h2>
        <p>{{ publicInformations.debouches }}</p>
    </div>
    <div>
        <h2><b>Taux d'obtention du titre : </b></h2>
        <p>{{ publicInformations.tauxObentionDuTitre }}</p>
    </div>
    <div>
        <h2><b>taux de satisfaction stagiaire : </b></h2>
        <p>{{ publicInformations.tauxSatisfactionStagiaire }}</p>
    </div>
    <div>
        <h2><b>taux de retour a l'emploi : </b></h2>
        <p>{{ publicInformations.tauxRetourAlEmploi }}</p>
    </div>
    <div>
        <h2><b>taux de poursuite d'étude : </b></h2>
        <p>N/A</p>
    </div>
    <div>
        <h2><b>taux de presentation a l'examen : </b></h2>
        <p>N/A</p>
    </div>

</div>
