// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

import {SERVICE_CATALOGUE} from '../app/app.constants';

export const environment = {
  production: false,
  environmentName: 'DEV',
  // apiUrl: 'http://localhost:8080' + SERVICE_CATALOGUE,
  apiUrl: 'http://ec2-13-38-18-224.eu-west-3.compute.amazonaws.com:8100' + SERVICE_CATALOGUE,
  appName: 'ADAME-TECHS',
  levelsDict: {
    1: 'Front-end',
    2: 'Back-end',
    3: 'Concepts et paradigmes',
    4: 'Cloud',
    5: 'Systemes',
    6: 'Machines virtuelles',
    7: 'Contrat d\'Apprentissage',
    8: 'CPF'
  },
  routes: {
    home : {
      name: 'home'
    },
    activate: {
      name: 'activate'
    },
    subscribe : {
      name: 'subscribe'
    },
    gtcs : {
      name: 'gtcs'
    },
    courseList : {
      name: 'course-list',
      params: {
        courseCategoryId: 'course-category-id'
      }
    },
    course : {
      name: 'course',
      params: {
        courseCategoryId: 'course-category-id',
        courseId: 'course-id',
      }
    },
    video : {
      name: 'video',
      params: {
        courseCategoryId: 'course-category-id',
        courseId: 'course-id',
        chapterId: 'chapter-id',
        videoId: 'video-id'
      }
    },
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
