import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import * as _ from 'lodash';
import { Subscription, Observable } from 'rxjs';
import { Chapter } from '../../app/shared/model/chapter.model';
import { ClassLevel } from '../../app/shared/model/class-level.model';
import { Course, ICourse } from '../../app/shared/model/course.model';
import { PublicInformation } from '../../app/shared/model/public-information.model';
import { environment } from '../../environments/environment';
import { createRequestOption } from '../../app/shared/util/request-util';

export interface PeriodicElement {
  position: number;
  name: string;
  chapter: Chapter;
}


type EntityResponseType = HttpResponse<ICourse>;
type EntityArrayResponseType = HttpResponse<ICourse[]>;

@Injectable({
  providedIn: 'root'
})
export class CourseService {

  public resourceUrl = environment.apiUrl + '/api/courses';

  dataSource: PeriodicElement[];
  columnsToDisplay = ['no', 'titre'];
  expandedElement: PeriodicElement | null;

  public coursesList: Array<Course> = [];
  public course: Course;
  public publicInformations: PublicInformation;


  constructor(private http: HttpClient) { }

  setCourseDetail(courseid: string): Subscription {
    return this.http.get(environment.apiUrl + '/api/public-informations').subscribe(publicInformationsObj => {
      this.publicInformations = publicInformationsObj as PublicInformation;
    }, err => console.log(err));
  }

  getOneLevelCategoriesList(classLevelId: string, classLevelsList: Array<ClassLevel>): ClassLevel {
    let classLevel: ClassLevel;

    for (const oneClassLevel of classLevelsList) {
      if (oneClassLevel.id === classLevelId) {
        classLevel = oneClassLevel;
      }
    }

    return classLevel;
  }

  setCourse(courseId: string): Subscription {
    this.course = {} as Course; // initialize so we dont have data from previous navigations

    return this.http.get(environment.apiUrl + '/api/courses/' + courseId).subscribe(courseObj => {
      this.course = courseObj as Course;
    }, err => console.log(err));
  }

  setChaptersLessonsList() {
    const chaptersNumber = this.course.chapters.length;

    for (let i = 0; i < chaptersNumber; i++) { // makes http requests for each chapters, this is not optimal...
      const chapterId = this.course.chapters[i].id;

      this.http.get(environment.apiUrl + '/api/chapters/' + chapterId).subscribe(chapterObjs => {
        this.course.chapters[i] = chapterObjs as Chapter;

        if (++i === chaptersNumber) {
          this.course.chapters = this.sortChaptersByParentChapter(this.course.chapters);
          this.dataSource = this.getChapters(this.course.chapters);
        }
      }, err => console.log(err));
    }
  }

  getChapters(chapterList: Array<Chapter>): PeriodicElement[] {
    const elements = [];
    let count = 1;

    for (const oneChapter of chapterList) {
      elements.push({no: count, titre: oneChapter.name, chapter: oneChapter});
      count++;
    }

    return elements;
  }

  sortChaptersByParentChapter(chaptersList: Array<Chapter>): Array<Chapter> {
    // @ts-ignore
    // tslint:disable-next-line:no-unused-expression
   const chapterSorted = chaptersList.sort((a, b) => (a.parentChapter - b.parentChapter));
   return chapterSorted;
  }


 create(course: ICourse): Observable<EntityResponseType> {
    return this.http.post<ICourse>(this.resourceUrl, course, { observe: 'response' });
  }

  update(course: ICourse): Observable<EntityResponseType> {
    return this.http.put<ICourse>(this.resourceUrl, course, { observe: 'response' });
  }

  find(id: string): Observable<EntityResponseType> {
    return this.http.get<ICourse>(`${this.resourceUrl}/${id}`, { observe: 'response' });
  }

  query(req?: any): Observable<EntityArrayResponseType> {
    const options = createRequestOption(req);
    return this.http.get<ICourse[]>(this.resourceUrl, { params: options, observe: 'response' });
  }

  delete(id: string): Observable<HttpResponse<any>> {
    return this.http.delete<any>(`${this.resourceUrl}/${id}`, { observe: 'response' });
  }

}
