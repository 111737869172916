<div class="content">
    <div class="content-video">
        <video class="video" [src]="lessonInfos.videoUrl" (click)="playPause()"
               #videoPlayer></video>
        <div class="controls">
            <div class="bar">
                <div class="juice" [ngStyle]="{ 'width': juiceWidth }"></div>
            </div>
            <div class="buttons-left">
                <button>
                    <mat-icon svgIcon="previous" aria-hidden="false" aria-label="previous"></mat-icon>
                </button>
                <button (click)="playPause()">
                    <mat-icon *ngIf="isPaused === true" svgIcon="play" aria-hidden="false" aria-label="play"></mat-icon>
                    <mat-icon *ngIf="isPaused === false" svgIcon="pause" aria-hidden="false" aria-label="pause"></mat-icon>
                </button>
                <button>
                    <mat-icon svgIcon="next" aria-hidden="false" aria-label="next"></mat-icon>
                </button>
            </div>

            <span class="duration">{{ timeDisplay }}</span>

            <div class="controls-spacer"></div>
            <div class="buttons-right">
                <button (click)="toggleMute()" class="toggleFullScreen">
                    <mat-icon svgIcon="no-volume" aria-hidden="false" aria-label="no-volume"></mat-icon>
                </button>
                <button (click)="lessVolume()" class="toggleFullScreen">
                    <mat-icon svgIcon="less-volume" aria-hidden="false" aria-label="less-volume"></mat-icon>
                </button>
                <button (click)="moreVolume()" class="toggleFullScreen">
                    <mat-icon svgIcon="more-volume" aria-hidden="false" aria-label="more-volume"></mat-icon>
                </button>
                <button (click)="toggleFullScreen()" class="toggleFullScreen">
                    <mat-icon *ngIf="isFullScreen === false" svgIcon="full-screen" aria-hidden="false" aria-label="full-screen"></mat-icon>
                    <mat-icon *ngIf="isFullScreen === true" svgIcon="full-screen-exit" aria-hidden="false" aria-label="full-screen-exit"></mat-icon>
                </button>
            </div>
        </div>
    </div>
</div>
