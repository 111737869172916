import { Component } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { stepper } from './route-animations';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { SERVER_API_URL, SERVICE_CATALOGUE} from './app.constants';

export interface RespToken {
  id_token: string;
}

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  animations: [
    // fader,
    // slider,
    // transformer,
    stepper
  ]
})
export class AppComponent {
  title = 'netclass';

  auth: any = {
    password: 'user',
    rememberMe: false,
    username: 'user'
  };


  constructor(private http: HttpClient ) {
    this.singup();

  }

  getData() {}

  singup() {

    console.log(this.auth.password + ' username :' + this.auth.username + ' ' + SERVER_API_URL + ' ' + SERVICE_CATALOGUE );
    this.http.post(SERVER_API_URL + '/api/authenticate', this.auth).subscribe(response => {
      const resp = response as RespToken;
      localStorage.setItem('authenticationToken', resp.id_token);
      // console.log(localStorage.getItem('id_token'));
      }, err => console.log(err));

  }

  prepareRoute(outlet: RouterOutlet) {
    return outlet && outlet.activatedRouteData && outlet.activatedRouteData['animation'];
  }

}
