<div class="content">
    <h1>Conditions Générales de Vente</h1>
</div>

<p>Les présentes Conditions Générales de Vente sont à jour au 1er janvier 2020.</p>

<div>
    <h2>1. Champ d’application des conditions générales de vente</h2>

    <p>
        1.1. Les présentes Conditions Générales de Vente (ci-après « CGV » ou le « Contrat ») sont conclues
        entre l’Association ADAME-TECHS Centre de formation Informatique – SIREN 820 856 870 – code NAF 6202A, dont le
        siège social est 2 bis rue pierre de Ronsard, 78200 Mantes-La-Jolie, France (ci-après la « Société ») et le candidat
        (ci-après le « Client ») qui procède à son inscription en ligne à un événement, à un concours ou
        à toute formation organisés par un des établissements d’enseignement de la Société.
    </p>
    <p>
        Le Client et la Société sont dénommés individuellement une « Partie » et collectivement les
        «Parties ».
    </p>
    <p>
        1.2. Elles s’appliquent de plein droit à toute vente des produits et services suivants
        (les « Prestations »), sans que cette liste soit limitative, ainsi qu’à tout autre produit ou service
        proposé au Client par la Société ainsi que tout établissement appartenant directement ou indirectement à la
        Société : Inscription à un événement ou à un concours d’entrée dans un des établissements d’enseignement de
        la Société ; Achat de prestations de services tel que précisé sur les différents sites administrés
        directement ou indirectement par la Société.
        Ces CGV ont également vocation à s’appliquer à toute prestation de la Société négociée et acceptée par le Client
        sur cette base.
    </p>
</div>
<div>
    <h2>2. Application et opposabilité des CGV</h2>

    <p>
        2.1. Les présentes CGV ont pour objet de définir l’ensemble des conditions dans lesquelles la Société
        commercialise les produits et services tels que proposés à la vente aux Clients en ligne sur les sites
        suivants : www.adame-techs.eu/ (ci-après le ou les « Site(s) »). Elles s’appliquent donc à toute
        Commande (« Commande ») de produits ou services passée par le Client sur les Sites.
    </p>
    <p>
        2.2. Le Client déclare avoir pris connaissance et accepté les présentes CGV avant la passation de sa Commande.
    </p>
    <p>
        2.3. La validation de la Commande et des différents éléments qui en découlent vaut donc acceptation de ces CGV.
        Celles-ci sont régulièrement mises à jour. Les CGV applicables sont celles en vigueur à la date de passation
        de la Commande.
    </p>
    <p>
        2.4. Toute condition contraire posée par le Client notamment ses conditions générales d’achat par exemple serait
        donc, à défaut d’acceptation expresse, inopposable à la Société quel que soit le moment où elle aura pu être
        portée à sa connaissance.
    </p>
    <p>
        2.5. Le fait que la Société ne se prévale pas à un moment donné d’une quelconque disposition des présentes CGV,
        ne peut être interprété comme valant renonciation à se prévaloir ultérieurement d’une quelconque disposition
        desdites CGV.
    </p>
</div>
<div>
    <h2>3. Commande et exécution des prestations</h2>
    <p>
        3.1. Les Prestations proposées à la vente sont décrites et présentées avec la plus grande exactitude possible
        sur les Sites. Néanmoins, une variation minime dans la présentation des Prestations n’engage pas la
        responsabilité de la Société et n’affecte pas la validité de la vente.
    </p>
    <p>
        3.2. La Société se réserve le droit de corriger le contenu du Site à tout moment.
    </p>
    <p>
        3.3. Le Client sélectionne le ou les Prestations qu’il souhaite acheter, et peut accéder à tout moment au récapitulatif de sa Commande.
    </p>
    <p>
        3.4. Le récapitulatif de la Commande présente la liste de la ou des Prestations que le Client a
        sélectionnée(s), et comprend les éventuels frais annexes tels que les frais de dossier venant s’ajouter au prix
        du ou des Prestations de la Commande. Le Client a la possibilité de modifier sa Commande et de corriger
        d’éventuelles erreurs avant de procéder à l’acceptation de sa Commande.
    </p>
    <p>
        3.5. Après avoir accédé au récapitulatif de sa Commande, le Client confirme l’acceptation de sa Commande en
        cochant la case de validation des CGV, puis en cliquant sur l’icône de validation de la Commande.
        La mention « Commande avec obligation de paiement » ou une formule analogue dénuée de toute ambiguïté figure à
        côté de l’icône de validation de la Commande afin de s’assurer que le Client reconnaît explicitement son
        obligation de paiement de la Commande.
    </p>
    <p>
        3.6. Après acceptation des CGV et validation de la Commande avec obligation de paiement, le contrat est
        valablement conclu entre la Société et le Client et les engage de manière irrévocable.
    </p>
    <p>
        3.7. La Société lui envoie alors une confirmation de Commande par email, reprenant les éléments du récapitulatif
        de sa Commande et le cas échéant de facturation renseignées.
    </p>
    <p>
        3.8. Après avoir validé ses coordonnées, le Client procède au paiement de sa Commande selon les modalités
        précisées ci-après.
    </p>
    <p>
        3.9. Toute Commande passée et signée par le Client vaut engagement ferme et définitif qui ne peut être remis en
        cause.
    </p>
</div>
<div>
    <h2>4. Collaboration des parties</h2>

    <p>
        4.1. Le Client s’engage à accepter toute discussion avec la Société lorsque cette dernière estime qu’un échange
        est nécessaire à la réalisation conforme des Prestations objets du Contrat.
    </p>
    <p>
        De manière générale, le Client s’engage à collaborer pleinement avec la Société afin de le placer dans les
        meilleures conditions possibles pour réaliser les prestations.
    </p>
    <p>
        A cet égard, le Client reconnait ainsi que son implication et sa collaboration sont nécessaires pour assurer la
        bonne exécution du Contrat par la Société.
    </p>
    <p>
        4.2. En cas de survenance d’un événement de nature à retarder l’exécution de la Prestation, la Partie concernée
        par l’événement se doit de prévenir l’autre Partie sans délai par tout moyen de communication approprié à
        l’urgence de la situation.
    </p>
    <p>
        Le Client ne pourra reprocher à la Société un retard dans l’exécution de la Prestation s’il tarde ou refuse de
        lui communiquer des informations essentielles à la réalisation des prestations.
    </p>
    <p>
        4.3. En l’absence de collaboration du Client avec la Société, celui-ci ne pourra reprocher à la Société un
        manquement dans l’exécution des Prestations objet des présentes.
    </p>
</div>
<div>
    <h2>5. Obligations du client</h2>

    <p>
        5.1. Compte-tenu de la nature des produits et services vendus et proposés par la Société, le Client reconnait
        et accepte qu’il est soumis à une obligation de coopération avec la Société.
    </p>
    <p>
        5.2. A ce titre, le Client reconnait qu’il est soumis à une obligation de résultat en ce qui concerne les
        sollicitations qui lui sont adressées par la Société aux fins de permettre à cette dernière d’exécuter les
        Prestations commandées par le Client. La collaboration du Client avec la Société est ainsi déterminante de la
        bonne exécution des Prestations.
    </p>
    <p>
        5.3. En outre, le Client devra communiquer et fournir à la Société toute information de nature à permettre la
        bonne exécution des Prestations et ce, afin de lui permettre d’appréhender ses besoins et ses demandes.
    </p>
    <p>
        5.4. Le Client mettra également à disposition tout élément de nature à permettre à la Société d’exécuter ses
        obligations.
    </p>
    <p>
        5.5. Outre les obligations susvisées, le Client s’engage également à payer le prix convenu dans les délais
        fixés.
    </p>
</div>
<div>
    <h2>6. Prix et conditions de paiement de la commande</h2>

    <p>
        6.1. Les prix sont mentionnés dans les descriptifs des Prestations sur le Site, en euros toutes taxes comprises.
    </p>
    <p>
        6.2. Le montant total est indiqué dans le récapitulatif avant que le Client n’accepte les présentes CGV, valide
        sa Commande. Ce montant total est indiqué en toutes taxes comprises.
    </p>
    <p>
        6.3. La Commande des Prestations est payable en euros. Les modalités de paiement seront précisées par la Société
        en fonction de chaque situation. A titre informatif, le paiement pourra être effectué par carte bancaire, par
        chèque bancaire, par chèque postal.
    </p>
    <p>
        6.4. La Société se réserve le droit de suspendre ou d’annuler toute exécution et/ou livraison d’une Commande,
        quels que soient sa nature et son niveau d’exécution, en cas de défaut de paiement ou de paiement partiel de
        toute somme qui serait due par le Client à la Société, en cas d’incident de paiement d’une Commande.
    </p>
</div>
<div>
    <h2>7. Responsabilité</h2>

    <p>
        7.1. La Société met en œuvre toutes les mesures propres à assurer au Client la fourniture des Prestations dans
        des conditions optimales. Elle ne saurait cependant en aucun cas voir sa responsabilité engagée pour toute
        inexécution ou mauvaise exécution de tout ou partie des prestations prévues au contrat, qui serait imputable
        soit au Client, soit au fait imprévisible et insurmontable d’un tiers étranger au contrat, soit à un cas de
        force majeure tel que précisé par l’article 1218 du code civil et la jurisprudence applicable.
    </p>
    <p>
        Plus généralement, si la responsabilité de la Société se trouvait engagée, elle ne pourrait en aucun cas
        accepter d’indemniser le Client pour des dommages indirects ou dont l’existence et/ou le quantum ne seraient
        pas établi par des preuves.
    </p>
    <p>
        7.2. Il est expressément stipulé que la Société ne pourra en aucun cas être tenue responsable, de quelque
        manière que ce soit, pour le cas où le matériel informatique ou la messagerie électronique des Clients
        rejetterait, par exemple du fait d’un anti-spam, les courriers électroniques adressés par la Société, et
        notamment, sans que cette liste ne soit exhaustive, la copie du ticket de paiement, l’état récapitulatif de
        la Commande ou encore le courrier électronique de suivi d’expédition.
    </p>
    <p>
        7.3. En outre, la Société ne saurait être responsable de tout manquement concernant l’hébergement de données
        qui serait lié à des défauts techniques qui ne relèvent pas du contrôle de la Société.
    </p>
    <p>
        7.4. Le Client a pleinement conscience des dispositions du présent article et notamment des garanties et
        limitations de responsabilité susvisées, conditions essentielles sans lesquelles la Société n’aurait jamais
        contracté.
    </p>
</div>
<div>
    <h2>8. Données personnelles – Sécurité</h2>

    <p>
        8.1. La Société attache une grande importance au respect de la vie privée et prend toutes les mesures
        nécessaires pour assurer la confidentialité et la sécurité des données personnelles des Clients.
    </p>
    <p>
        8.2. Dans le cadre de la fourniture, la Société collecte des données personnelles des Clients et notamment les
        données suivantes :Adresse email, Prénom, Nom, Adresse postale, Date de naissance, Pays, Mot de Passe,
        Coordonnées bancaires, Numéro de téléphone du Client
    </p>
    <p>
        8.3. La Société collecte et traite les données personnelles des Clients pour les finalités suivantes :
        Fourniture et gestion des Prestations ; Information sur le Client et sur les activités du Client ;Réponse
        aux éventuelles questions/réclamations des Clients ; Elaboration de statistiques ; Gestion des demandes
        de droits d’accès, de rectification et d’opposition.
    </p>
    <p>
        8.4. Les données relatives à la gestion des données personnelles des Clients sont conservées pour la durée
        strictement nécessaire telle que définie par la Loi Informatique et Libertés telle que modifiée soit trois ans
        après la collecte ou le dernier contact avec le Client.
    </p>
    <p>
        8.5. Les données personnelles des Clients sont traitées par le service commercial de la Société ainsi que par
        les sociétés partenaires et sous-traitants de la Société.
    </p>
    <p>
        8.6. La Société peut également communiquer les données personnelles afin de coopérer avec les autorités
        administratives et judiciaires.
    </p>
    <p>
        8.7. La Société veille à sécuriser les données personnelles des Clients de manière adéquate et appropriée et a
        pris les précautions utiles afin de préserver la sécurité et la confidentialité des données et notamment
        empêcher qu’elles ne soient déformées, endommagées ou communiquées à des personnes non autorisées.
    </p>
    <p>
        8.8. Obligations des Clients : Les Clients reconnaissent que les données personnelles divulguées par eux
        sont valides, à jour et adéquates ; Les Clients s’engagent à ne pas porter atteinte à la vie privée, à
        l’image et à la protection des données personnelles de toute personne tierce et ainsi à ne pas communiquer à la
        Société les données de personnes tierces sans leur consentement.
    </p>
    <p>
        8.9. En vertu du Décret n°2011-219 du 25 février 2011 relatif à la conservation et à la communication des
        données permettant d’identifier toute personne ayant contribué à la création d’un contenu mis en ligne,
        le Client est informé que l’hébergeur d’un site a l’obligation de conserver pendant une durée d’un an à compter
        du jour de la création des contenus, pour chaque opération contribuant à la création d’un contenu :
        L’identifiant de la connexion à l’origine de la communication ; L’identifiant attribué par le système
        d’information au contenu, objet de l’opération ; Les types de protocoles utilisés pour la connexion au service
        et pour le transfert des contenus ; La nature de l’opération ; Les date et heure de l’opération ;
        L’identifiant utilisé par l’auteur de l’opération lorsque celui-ci l’a fourni.
    </p>
    <p>
        8.10. En cas de résiliation du Contrat ou de la fermeture du compte, l’hébergeur doit également conserver
        durant un an à compter du jour de la résiliation du Contrat ou de la fermeture du compte les informations
        fournies lors de la souscription d’un contrat (Commande) par le Client ou lors de la création d’un compte, à
        savoir : Au moment de la création du compte : l’identifiant de cette connexion ; Les nom et prénom
        ou la raison sociale ; Les adresses postales associées ; Les pseudonymes utilisés ; Les adresses de courrier
        électronique ou de compte associées ; Les numéros de téléphone ; Le mot de passe ainsi que les données
        permettant de le vérifier ou de le modifier, dans leur dernière version mise à jour.
    </p>
</div>
<div>
    <h2>9. Propriété intellectuelle</h2>

    <p>
        L’ensemble des éléments de ce Site et le Site lui-même, sont protégés par le droit d’auteur, le droit des
        marques, des dessins et modèles et/ou tous autres droits de propriété intellectuelle. Ces éléments sont la
        propriété exclusive de la Société. L’ensemble de ces droits est réservé pour le monde entier.
    </p>
    <p>
        Le nom et la marque ADAME-TECHS, les logos, les dessins et modèles, lettres stylisées, marques figuratives, et
        tous les signes représentés sur ce Site sont et demeureront la propriété exclusive de la Société.
    </p>
    <p>
        Aucun titre ni droit quelconque sur aucun élément ou logiciel ne sera obtenu par téléchargement ou copie
        d’éléments de ce Site. Il est formellement interdit au Client de reproduire (à part pour son utilisation
        personnelle et non commerciale), publier, éditer, transmettre, distribuer, montrer, enlever, supprimer,
        ajouter à ce Site et aux éléments et logiciels qu’il contient, pas plus que les modifier ou effectuer un
        quelconque travail en les prenant pour base, ni vendre ou participer à aucune vente en rapport avec ce Site,
        les éléments de ce Site ni aucun logiciel y afférant. La Société concède au Client une licence non exclusive
        pour utiliser le Site. Cette licence est strictement personnelle et ne peut en aucun cas être cédée ou
        transférée à quel que tiers que ce soit. La licence est concédée pour la durée d’utilisation du Site.
    </p>
    <p>
        Toute utilisation par le Client des dénominations sociales, marques et signes distincts appartenant à la
        Société est strictement prohibée sauf en cas d’accord exprès et préalable de la Société.
    </p>
</div>
<div>
    <h2>10. Droit à l’image</h2>

    <p>
        Le Client reconnait qu’il accepte l’utilisation et l’exploitation non commerciale
        (papier, télédiffusée, Internet et réseaux sociaux) de son image dans le cadre de la communication, de
        l’information et de la promotion de la Société et des établissements d’enseignement qu’elle gère et notamment
        la diffusion sur le/les sites de cette entité, ainsi que leur reproduction sur quelque support que ce soit.
    </p>
    <p>
        Le Client cède à la Société, sans contrepartie, les droits d’exploitation
        (spécialement les droits d’adaptation, de reproduction, de représentation et de diffusion) des photographies.
        La cession est sans limite de temps.
    </p>
    <p>
        La Société s’interdit expressément, de procéder à une exploitation des images susceptible de porter atteinte à
        la vie privée et à la réputation, d’utiliser les photographies et films objets de la présente pour toute autre
        exploitation préjudiciable.
    </p>
    <p>
        Le Client renonce expressément à se prévaloir d’un quelconque droit à l’image et à toute action à l’encontre de
        la Société ou toute entité gérée directement ou indirectement par elle, qui trouverait son origine dans
        l’exploitation des photographies, totale ou partielle.
    </p>
    <p>
        Cette cession est réalisée à titre gratuit.
    </p>
    <p>
        Le Client reconnait être informé du droit de rectifier ou retirer l’autorisation ainsi accordée.
    </p>
</div>
<div>
    <h2>11. Newsletter</h2>

    <p>
        En donnant expressément son accord à cette fin par l’acceptation des présentes, le Client accepte que la
        Société puisse lui faire parvenir, à une fréquence et sous une forme déterminée par le Client, une newsletter
        (lettre d’information) pouvant contenir des informations relatives à son activité.
    </p>
</div>
<div>
    <h2>12. Force majeure</h2>

    <p>
        Aucune des Parties ne pourra être tenue responsable de son retard ou de sa défaillance dans l’exécution de ses
        obligations contractuelles si ce retard ou cette défaillance sont dus à la survenance d’un événement échappant
        à son contrôle, qui ne pouvait pas être raisonnablement prévu lors de la conclusion du Contrat et dont les
        effets ne peuvent être évités par des mesures appropriées.
    </p>
    <p>
        En cas de survenance d’un tel cas de force majeure, l’exécution du présent Contrat sera suspendue jusqu’à
        disparition, extinction ou cessation du cas de force majeure. Toutefois, si le cas de force majeure perdure
        au-delà d’un délai de trente (30) jours, les Parties doivent se rapprocher afin de discuter d’une éventuelle
        modification du Contrat.
    </p>
    <p>
        Les échéances prévues par le présent Contrat seront automatiquement reportées en fonction de la durée du cas de
        force majeure.
    </p>
    <p>
        En l’absence d’un accord des Parties dans un délai de trente (30) jours et si le cas de force majeure perdure,
        chacune des Parties aura le droit de résoudre le présent Contrat de plein droit, sans qu’aucune indemnité ne
        soit due par l’une ou l’autre des Parties, par lettre recommandée avec accusé de réception adressée à l’autre
        Partie.
    </p>
</div>
<div>
    <h2>13. Droit de rétractation</h2>

    <p>
        Par les présentes, le Client déclare qu’il entend renoncer à son droit de rétractation pour bénéficier des
        Prestations dès la validation de la Commande.
    </p>
</div>
<div>
    <h2>14. Résiliation</h2>

    <p>
        14.1. En cas de manquement d’une des Parties à une de ses obligations essentielles, l’autre Partie pourra, en
        cas de mise en demeure par lettre recommandée avec accusé de réception restée infructueuse, prononcer la
        résiliation du Contrat de plein droit sans aucune autre formalité particulière et sans préjudice de toute
        demande de dommages-intérêts.
    </p>
    <p>
        14.2. La mise en demeure devra indiquer un délai raisonnable dans lequel la Partie contrevenante devra remédier
        à l’inexécution ou la mauvaise exécution de l’obligation essentielle objet de la mise en demeure. À peine de
        nullité, la mise en demeure devra impérativement mentionner la présente clause résolutoire.
    </p>
</div>
<div>
    <h2>15. Loi applicable et attribution de juridiction</h2>

    <p>
        15.1. Les présentes CGV sont régies et interprétées conformément au droit français, sans tenir compte des
        principes de conflits de lois.
    </p>
    <p>
        15.2. En cas de litige susceptible de survenir à l’occasion de l’interprétation et/ou de l’exécution des
        présentes ou en relation avec les présentes CGV, le Client et la Société feront leurs meilleurs efforts pour
        parvenir à un règlement amiable de leur litige.
    </p>
    <p>
        15.3. En cas d’échec de cette tentative de règlement amiable, les Parties entendent soumettre le litige à la
        compétence exclusive des tribunaux compétents en application des règles du code de procédure civile.
    </p>
</div>
