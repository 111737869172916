import { AfterViewInit, Component, ViewChild, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CourseService } from '../../services/course/course.service';
import { environment } from '../../environments/environment';
import { Course } from '../shared/model/course.model';
import { MatTooltip } from '@angular/material/tooltip';
import { CourseCategoryService } from '../../services/category/course-category.service';

@Component({
  selector: 'app-course-list',
  templateUrl: './course-list.component.html',
  styleUrls: ['./course-list.component.scss'],
  // Need to remove view encapsulation so that the custom tooltip style defined in
  // `tooltip-custom-class-example.css` will not be scoped to this component's view.
  encapsulation: ViewEncapsulation.None,
})
export class CourseListComponent implements AfterViewInit {
  @ViewChild('tooltip') tooltip: MatTooltip;
  public env = environment;
  public courseListGroupByLevels: Array<Array<Course>> = [];
  private readonly classLevelId: string;

  constructor(private router: Router,
              private route: ActivatedRoute,
              private courseService: CourseService,
              public coursesCategoryService: CourseCategoryService) {

    this.classLevelId = this.route.snapshot.paramMap.get(environment.routes.courseList.params.courseCategoryId);

    const courseListOneLevel = this.courseService.getOneLevelCategoriesList(this.classLevelId,
                                                                            this.coursesCategoryService.formationDomainList);

    this.coursesCategoryService.setOneClassLevelCategoriesList(courseListOneLevel);
  }

  ngAfterViewInit() {
    const intervalId = setInterval(() => {
      if (this.tooltip !== undefined) {
        this.tooltip.show();
        this.tooltip.hideDelay = 60000;
        clearInterval(intervalId);
      }
    }, 1000);
  }

  goToCourse(courseName: string, courseId: string) {
    this.router.navigate([environment.routes.course.name, this.classLevelId, courseId]);
  }
}
