<div class="course-header" *ngIf="courseService.course !== undefined"
  [ngStyle]="{ 'background-image': 'url(' + courseService.course.imgUrl + ')'}">
  <div class="fade"></div>
</div>

<div class="btns">
  <button mat-raised-button (click)="openCourseDescriptionDialog()">Fiche descriptive de la formation</button>
<!--  <a href="{{ courseService.course.programmeFileUrl }}" download>-->
<!--    <button mat-raised-button>Télécharger le programme de la formation</button>-->
<!--  </a> -->
  <!-- <jhi-register></jhi-register> -->
</div>


<div [ngSwitch]="isAuthenticated()">
  <!-- div englobante -->
  <mat-card class="avancement" *ngSwitchCase="true">
    <h2 style="margin:auto">Votre progressions sur ce cours est de </h2>
    <div class="spinner-container">
      <div class="spinner-background">{{avancementCours}} %</div>
      <mat-progress-spinner [color]="color" [mode]="mode" [value]="avancementCours">
      </mat-progress-spinner>
    </div>
  </mat-card>

  <div class="content">

    <div class="chapters">
      <!-- plus tard mettre une condition sur le H1 POUR AFFICHER COUR TERMINER avec le chargement du certificat. -->

      <table mat-table [dataSource]="courseService.dataSource" multiTemplateDataRows
        class="mat-elevation-z8 flexbox-item">


        <ng-container matColumnDef="{{ column }}" *ngFor="let column of courseService.columnsToDisplay">
          <th mat-header-cell *matHeaderCellDef> {{ column }} </th>
          <td mat-cell *matCellDef="let element"> {{ element[column] }}</td>
          <!-- avanecement stat chapitre -->
          <!-- <mat-panel-description [ngClass]="{'end': lesson.selectedOptions.selected.length / typeOfvariables.length * 100 == 100}">
        cour {{ (lesson.selectedOptions.selected.length / element.chapter.lessons.length * 100 ==100) ? 'terminer': (lesson.selectedOptions.selected.length / element.chapter.lessons.length * 100 > 0) ? 'en apprentissage' : ' non démarré'}}
      </mat-panel-description> -->

        </ng-container>

        <!-- Expanded Content Column - The detail row is made up of this one column that spans across all columns -->
        <ng-container matColumnDef="expandedDetail" >
          <td mat-cell *matCellDef="let element; let indexChapters = index; "
            [attr.colspan]="courseService.columnsToDisplay.length">
            <div class="example-element-detail"
              [@detailExpand]="element == courseService.expandedElement ? 'expanded' : 'collapsed'">
              <!-- affichage des lessons -->
              <mat-selection-list #lesson [(ngModel)]="selectedLessons" *ngSwitchCase="true">
                <span style="font-size: x-large;  ">
                  {{(lesson.selectedOptions.selected.length / element.chapter.lessons.length * 100 ==100) ? 'Chapitre Terminé': (lesson.selectedOptions.selected.length / element.chapter.lessons.length * 100 > 0) ? 'Chapitre en cours d\'apprentissage' : ' Chapitre non démarré'}}
                </span>
                <p>
                  vous avez réalisé :
                  {{lesson.selectedOptions.selected.length / element.chapter.lessons.length * 100 | number: '1.0-0'}} %
                  du Chapitre et <strong>{{avancementCours}} %</strong> du cours
                </p>
                <mat-list-option *ngFor="let oneVideo of element.chapter.lessons" [value]="oneVideo.name"
                  (click)="saveSelectedLesson(oneVideo.name, element.chapter.name)">

                  <button style="text-align:left" mat-button
                    (click)="goToLesson(element.chapter.id, oneVideo.id); ">{{ oneVideo.name }}</button>
                </mat-list-option>
                <p>
                  vous avez réalisé :
                  {{lesson.selectedOptions.selected.length / element.chapter.lessons.length * 100 | number: '1.0-0'}} %
                  du Chapitre et <strong>{{avancementCours}} %</strong> du cours
                </p>

              </mat-selection-list>

              <mat-list role="list" >
                <mat-list-item role="listitem" *ngFor="let oneVideo of element.chapter.lessons;">
                  <div *ngSwitchCase="false">
                    <button  mat-button *ngIf="oneVideo.demoVisible!==true" >  {{ oneVideo.name }}</button>
                    <button  mat-button *ngIf="oneVideo.demoVisible===true"  (click)="goToLesson(element.chapter.id, oneVideo.id)" >{{ oneVideo.name }} - Voir la démo</button>
                  </div>

                  <!-- TODO: a supprimer 
                     cette partie est déja appeler a la ligne :65 et n'est pas utile 
                    <button mat-button *ngSwitchCase="true" (click)="goToLesson(element.chapter.id, oneVideo.id)">{{ oneVideo.name }}</button> -->
                </mat-list-item>
              </mat-list>
            </div>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="courseService.columnsToDisplay"></tr>
        <tr mat-row *matRowDef="let element; columns: courseService.columnsToDisplay;" class="example-element-row"
          [class.example-expanded-row]="courseService.expandedElement === element" (click)="onClick(element)">
        </tr>
        <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="example-detail-row"></tr>
      </table>
    </div>
    <div class="chapter-content flexbox-item" *ngIf="selectedChapter !== undefined">
      <img src="{{ (selectedChapter.imgUrl !== null) ? selectedChapter.imgUrl : '../../assets/work-laptop.jpg' }}"
        alt="course-image" />
      <h2>{{ selectedChapter.name }}</h2>
      <p>{{ selectedChapter.description }}</p>
    </div>
    <div class="spacer"></div>
  </div>
</div>
