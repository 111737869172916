import { Component } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-navigation-bar',
  templateUrl: './navigation-bar.component.html',
  styleUrls: ['./navigation-bar.component.scss']
})
export class NavigationBarComponent {

    public roleRoute = '';

    constructor(private router: Router) {}

    signOut() {}

    signUpForm() {}

    signInForm() {}

    resetPasswordForm() {}

}
