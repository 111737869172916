import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from './home/home.component';
import { CourseListComponent } from './course-list/course-list.component';
import { CourseComponent } from './course/course.component';
import { VideoPlayerComponent } from './video-player/video-player.component';
import { environment } from '../environments/environment';
import { SubscribeComponent } from './subscribe/subscribe.component';
import { GtcsComponent } from './gtcs/gtcs.component';
import { ActivateComponent } from './header/activate/activate.component';

const routes: Routes = [
  { path: '', component: HomeComponent, data: { animation: 'toHome'} },
  { path: environment.routes.subscribe.name, component: SubscribeComponent},
  { path: environment.routes.activate.name, component: ActivateComponent},
  { path: environment.routes.gtcs.name, component: GtcsComponent},
  { path: environment.routes.home.name, component: HomeComponent, data: { animation: 'toHome'} },
  { path: environment.routes.courseList.name + '/:' + environment.routes.courseList.params.courseCategoryId,
    component: CourseListComponent, data: { animation: 'toCourseList'} },
  { path: environment.routes.course.name +
        '/:' + environment.routes.course.params.courseCategoryId +
        '/:' + environment.routes.course.params.courseId,
    component: CourseComponent, data: { animation: 'toCourse'} },
  { path: environment.routes.video.name +
        '/:' + environment.routes.video.params.courseCategoryId +
        '/:' + environment.routes.video.params.courseId +
        '/:' + environment.routes.video.params.chapterId +
        '/:' + environment.routes.video.params.videoId,
    component: VideoPlayerComponent, data: { animation: 'toVideo'} },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
