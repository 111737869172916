<div class="content">
  <br>
  <h1>{{ env.appName }}</h1>
  <br>
  <div class="rows" *ngFor="let oneCategory of coursesCategoryService.oneClassLevelCategoriesList; let i = index;">
    <span class="course-category">{{ oneCategory.name }}</span>
    <div class="wrapper">
        <swiper>
          <div *ngFor="let oneCourse of oneCategory.courses">
            <!-- non tooltiped version -->
            <img *ngIf="oneCourse.readyToPublish==true && oneCourse.id !== 1 && oneCourse.id !== 21" (click)="goToCourse(oneCourse.name, oneCourse.id)"
                 src="{{ oneCourse.imgUrl }}" alt="course-image"/>

            <!-- tooltiped version -->
            <img *ngIf=" oneCourse.id === 1 || oneCourse.id === 21"
                 (click)="goToCourse(oneCourse.name, oneCourse.id)"
                 #tooltip="matTooltip"
                 matTooltip="version d'évaluation disponible"
                 matTooltipClass="tooltip-red"
                 matTooltipPosition="above"
                 src="{{ oneCourse.imgUrl }}" alt="course-image"/>
          </div>
        </swiper>
    </div>
  </div>
</div>
